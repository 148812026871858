import { Component } from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {CommonModule} from "@angular/common";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {AuthService} from "../../Services/auth.service";
import Swal from "sweetalert2";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule,
    MatButtonModule, MatMenuModule, MatIconModule
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {

  isSubmitButtonDisabled=false

  email: string = ''; // Adresse e-mail de l'utilisateur
  forgetreset!:FormGroup ;
  constructor(private authService: AuthService , private fb:FormBuilder , private router:Router, private translate: TranslateService) {

    this.translate.addLangs(['en', 'fr']);

    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      }else{
        this.translate.setDefaultLang('fr');
      }

    } catch (error) {


    }
  }
  ngOnInit(){
    this.forgetreset = this.fb.group({
      email:['' , Validators.required]
    })


    this.translate.addLangs(['en', 'fr']);

    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      }else{
        this.translate.setDefaultLang('fr');
      }

    } catch (error) {


    }


  }


  changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
    localStorage.setItem("lang", lang)
  }

  getFlag(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "assets/en.png"
      } else {
        return "assets/fr.png"
      }
    } catch (e) {
      return "assets/fr.png"

    }
  }
  getDiffFlag(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "assets/fr.png"
      } else {
        return "assets/en.png"
      }
    } catch (e) {
      return "assets/en.png"

    }
  }
  getDiffLang(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "Francais"
      } else {
        return "English"
      }
    } catch (e) {
      return "English"

    }
  }
  getDiffLangAp(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "fr"
      } else {
        return "en"
      }
    } catch (e) {
      return "en"

    }
  }


  navigateToAbout() {
    this.router.navigate(['/forgot-password-success']);
  }

  onSubmit(): void {
    this.isSubmitButtonDisabled = true;
    let formData = new FormData();
    formData.append('email', this.forgetreset.value.email);
  
    // Envoyez la requête de réinitialisation de mot de passe
    this.authService.forgotPassword(formData).subscribe({
      next: data => {
        this.translate.get([
          'email_sent_title',
          'email_sent_message'
        ]).subscribe(translations => {
          Swal.fire({
            title: translations['email_sent_title'],
            text: translations['email_sent_message'],
            icon: 'success'
          });
        });
        // Redirigez si nécessaire
        // this.router.navigateByUrl('/new-password')
      },
      error: err => {
        this.isSubmitButtonDisabled = false;
        let errorMessage = 'An error occurred.';
        if (err.error && err.error.message) {
          errorMessage = err.error.message;
        }
        this.translate.get([
          'error_title',
          'error_message'
        ]).subscribe(translations => {
          Swal.fire({
            title: translations['error_title'],
            text: translations['error_message'],
            icon: 'error'
          });
        });
      }
    });
  }
  

  // Fonction pour valider l'email avec une expression régulière
  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
}
