import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { StorageServiceService } from '../storage.service.service';
interface AuthResponse {
  token: string;  // Ajoutez le type attendu pour le token ici
  // Ajoutez d'autres champs si nécessaire
}

const AUTH_API = 'https://soacwaaslearning.studiolab.fr/elearning/api/auth/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = 'https://soacwaaslearning.studiolab.fr/elearning/api/auth';
    constructor(private http: HttpClient, private storageService: StorageServiceService ) {}

  login(username: string, password: string): Observable<any> {
    return this.http.post<AuthResponse>(
      AUTH_API + 'signin',
      { username, password },
      httpOptions
    )
      .pipe(
        tap(response => {
          // Enregistrez les informations de l'utilisateur dans le sessionStorage
          this.storageService.saveUser({ token: response.token });
        }),
        catchError(error => {
          // Gérez les erreurs de connexion, si nécessaire
          console.error('Login error:', error);
          throw error;
        })
      );



  }


  getTotalAssistants(): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/count-assistants`);
  }
  getTotalStudents(): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/count-students`);
  }

  register(username: string, email: string, password: string, profileImageFile?: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('username', username);
    formData.append('email', email);
    formData.append('password', password);
  
    // Ajouter le fichier uniquement s'il est défini
    if (profileImageFile) {
      formData.append('profileImageFile', profileImageFile);
    }
  
    return this.http.post(AUTH_API + 'signup', formData).pipe(
      catchError(err => {
        console.error('Registration error:', err);
        return of(err);
      })
    );
  }
  
  registerUser1(
    username: string,
    email: string,
    password: string,
    name: string,
    last_name: string,
    sexe: string,
    number_phone: string,
    profileImageFile?: File // Fichier optionnel
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('username', username);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('name', name);
    formData.append('last_name', last_name);
    formData.append('sexe', sexe);
    formData.append('number_phone', number_phone);
  
    // Ajouter le fichier uniquement s'il est défini
    if (profileImageFile) {
      formData.append('profileImageFile', profileImageFile);
    }
  
    return this.http.post(`${AUTH_API}/signup`, formData).pipe(
      catchError(err => {
        console.error('Extended registration error:', err);
        return of(err);
      })
    );
  }
  

  logout(): Observable<any> {
    // Supprimez les informations de l'utilisateur du sessionStorage lors de la déconnexion
    this.storageService.clean();
    return this.http.post(AUTH_API + 'signout', {}, httpOptions);
  }

  getToken(): string | null {
    // Obtenez le token depuis le sessionStorage
    const user = this.storageService.getUser();
    return user?.token || null;
  }

  isLoggedIn(): boolean {
    // Vérifiez si l'utilisateur est connecté en fonction du token
    return !!this.getToken();
  }

  /**
   * Envoyer une demande de réinitialisation de mot de passe à l'adresse e-mail donnée.
   * @param email L'adresse e-mail de l'utilisateur.
   * @returns Un Observable d'un message de réponse.
   */
  // forgotPassword(email: string): Observable<any> {
  //   return this.http.post(AUTH_API + 'forgot-password', { email }, httpOptions)
  //     .pipe(
  //       catchError(err => {
  //         console.error('Erreur lors de la demande de réinitialisation de mot de passe:', err);
  //         throw err;
  //       })
  //     );
  // }

  forgotPassword(email: any): Observable<any> {

    return this.http.post(AUTH_API + 'forgot-password',email) ;
  }

  /**
   * Réinitialiser le mot de passe de l'utilisateur.
   * @param token Le jeton de réinitialisation.
   * @param newPassword Le nouveau mot de passe.
   * @returns Un Observable d'un message de réponse.
   */

  resetPassword(token: string, newPassword: string): Observable<any> {
    const params = new HttpParams()
      .set('token', token)
      .set('password', newPassword);

    return this.http.post<any>(AUTH_API + 'reset-password', {}, { params: params })
      .pipe(
        catchError(err => {
          console.error('Erreur lors de la réinitialisation de mot de passe:', err);
          throw err;
        })
      );
  }

  // La méthode pour obtenir l'image de profil d'un utilisateur
  getProfileImage(username: string): Observable<Blob> {
    // Le type `Blob` est utilisé pour représenter les données de l'image.
    return this.http.get(AUTH_API + `users/${username}/profileImage`, {
      responseType: 'blob'
    }).pipe(
      catchError(err => {
        console.error('Erreur lors de la récupération de l\'image de profil:', err);
        // Gérez l'erreur de manière appropriée ici
        throw err;
      })
    );
  }
}
