<app-nav></app-nav>
<app-path></app-path>
<div class="container">
    <app-description [picture]="picture" [name]="name" [category]="category" [videoUrl]="videoUrl"
        [briefing]="briefing"></app-description>
    <app-content [faqList]="faqList" [testimonials]="testimonials" [teachingMethod]="teachingMethod" [precondition]="precondition"
        [syllabus]="syllabus" [instructor]="instructor" [description]="description"></app-content>
    <app-join [mode]="mode" [apprentissage]="apprentissage" [somedate]="somedate" [somedate2]="somedate2" [formationIdentifier]="formationIdentifier" [passingScore]="passingScore" [name]="name" [id]="id" [price]="price"></app-join>
    <div>

        
    </div>
</div>
<!-- <app-footer></app-footer> -->