<div class="cources-module">
    <div class="container d-flex">
    <button (click)="createCource()" mat-mini-fab color="primary" style="background-color: #FFC825;"
        aria-label="Add Course">
        <mat-icon>add</mat-icon>
    </button>
    <h3 (click)="createCource()" role="button"  class="mx-4 w-auto ">  {{ 'ADD_ASSISTANT' | translate }}</h3>
</div>

    @for(cource of currentPage.content ; track cource.id){

    <div class="mt-1">
        <table class="table container">
            <thead>
                <tr class="row">
                    <th class="col-md-2 col-sm-3">{{ 'NAME' | translate }}</th>
                    <th class="col-md-3 col-sm-4">{{ 'EMAIL' | translate }}</th>
                    <th class="col-md-4 col-sm-3">{{ 'SEX' | translate }}</th>
                    <th class="col-md-3 col-sm-2">{{ 'ACTIONS' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="row">
                    <td class="col-md-2 col-sm-3">{{ cource.name }}</td>
                    <td class="col-md-3 col-sm-4">{{ cource.email }}</td>
                    <td class="col-md-4 col-sm-3">{{ cource.sexe }}</td>
                    <td class="col-md-3 col-sm-2">
                        <div class="d-inline-flex">
                            <button (click)="openDialog(cource.id)" mat-mini-fab color="primary"
                                style="background-color: #FFC825;" aria-label="Delete" class="button-spacing">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button (click)="updateCource(cource.id)" mat-mini-fab color="primary"
                                style="background-color: #FFC825;" aria-label="Edit" class="button-spacing">
                                <mat-icon>edit</mat-icon>
                            </button>

                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    } @empty {

    <div class="container p-md-4">
        <div class=" skeleton ">
            <div class="skeleton-left flex1">
                <div class="square"></div>
            </div>
            <div class="skeleton-right flex2">
                <div class="line h17 w40 m10"></div>
                <div class="line"></div>
                <div class="line h8 w50"></div>
                <div class="line  w75"></div>
            </div>
        </div>
    </div>
    }
    <app-pagination [pageNumber]="pageNumber" [pages]="pages" [goToPage]="goToPage"></app-pagination>
</div>