<div class="p-md-5">


    <mat-accordion>
        @if(showfrench){
        @for(faq of questions ; track faq.questionFr){
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ faq.questionFr }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ faq.answerFr }}</p>
        </mat-expansion-panel>
        }
        }

        @if(showenglish){
        @for(faq of questions ; track faq.questionFr){
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ faq.questionEn }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ faq.answerEn }}</p>
        </mat-expansion-panel>
        }
        }

    </mat-accordion>
</div>