import { HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../Services/auth.service';
import Swal from 'sweetalert2';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-create-assitant',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule
  ],
  templateUrl: './create-assitant.component.html',
  styleUrls: ['./create-assitant.component.scss']
})
export class CreateAssitantComponent {
  form: any = {
    username: null,
    email: null,
    password: null,
    name: null,
    last_name: null,
    sexe: null,
    number_phone: null
  };
  profileImageFile: File | null = null;
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';

  constructor(private authService: AuthService,private _snackBar: MatSnackBar, private router: Router,private translate: TranslateService) {

  }

  openSnackBar(message: string) {
    this._snackBar.open(message,"",{
      duration: 3000
    });
  }

  ngOnInit(): void {
    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");
      }else{
        this.translate.setDefaultLang('fr');
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }
  }

  onProfileImageFileChange(event: any): void {
    if (event.target.files && event.target.files.length > 0) {
      this.profileImageFile = event.target.files[0];
    }
  }
  changeLanguage=(lang:string)=>{
    this.translate.setDefaultLang(lang)
  }

  onSubmit(): void {
    const { username, email, password, name, last_name, sexe, number_phone } = this.form;
  
    if (this.profileImageFile) {
      this.authService.registerUser1(username, email, password, name, last_name, sexe, number_phone, this.profileImageFile).subscribe({
        next: data => {
          this.isSuccessful = true;
          this.isSignUpFailed = false;
  
          // Afficher une alerte de succès avec traduction
          this.translate.get(['creation_success_title', 'creation_success_message']).subscribe(translations => {
            Swal.fire({
              title: translations['creation_success_title'],
              text: translations['creation_success_message'],
              icon: 'success'
            });
          });
  
          this.router.navigate(['/dashboard-admin/assistant']);
        },
        error: err => {
          console.error('Registration error:', err);
  
          // Afficher un message d'erreur avec traduction
          this.translate.get(['registration_error_message1', 'registration_error_default1']).subscribe(translations => {
            this.openSnackBar(translations['registration_error_message1'] || translations['registration_error_default1']);
          });
  
          this.errorMessage = err.error?.message || 'An error occurred during registration.';
          this.isSignUpFailed = true;
        }
      });
    }
  }

  }