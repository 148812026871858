import { Component } from '@angular/core';

@Component({
  selector: 'app-question-type2',
  standalone: true,
  imports: [],
  templateUrl: './question-type2.component.html',
  styleUrl: './question-type2.component.scss'
})
export class QuestionType2Component {

}
