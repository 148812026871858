<div class="container p-md-5">
    <a href="" style="padding-bottom: 20px;">
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.5 7.99992H17.5M8.33333 3.33325L2.5 7.99992L8.33333 3.33325ZM2.5 7.99992L8.33333 12.6666L2.5 7.99992Z"
                stroke="#282938" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
        {{ 'HOMEPAGE' | translate }}
    </a>
    <div class="language-switcher">
        <span class="d-flex">
            <div class="d-flex px-md-5">
                <button mat-icon-button [matMenuTriggerFor]="menulang">
                    <img style="width: 30px; height: 30px;" [src]="getFlag()" alt="{{'AVATAR' | translate}}">
                </button>
                <button mat-icon-button [matMenuTriggerFor]="menulang">
                    <mat-icon>expand_more</mat-icon>
                </button>
            </div>

            <mat-menu #menulang="matMenu">
                <button mat-menu-item (click)="changeLanguage(getDiffLangAp())">
                    <span><img style="width: 35px; height: 35px;" [src]="getDiffFlag()"> {{getDiffLang()}}</span>
                </button>
            </mat-menu>
        </span>
    </div>


    <main class="row">
        <div class="col-md-6">
            <div>
                <h1>
                    <!-- {{ 'ONE_STEP_CLOSER' | translate }} -->
                </h1>
                <p>{{ 'E_LEARNING_SERVICE1' | translate }}</p>
            </div>
            <img src="assets/3228878.png">
        </div>

        <div class="col-md-6">
            <div class="content-page">
                <h2>{{ 'LOGIN' | translate }}</h2>
                <!-- <p>{{ 'PREPARE_FUTURE' | translate }}</p> -->

                @if(!isLoggedIn){
                <form class="row" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm">
                    <div class="mb-3">
                        <input type="email" class="form-control custom-input" name="username"
                            [(ngModel)]="form.username" placeholder="{{ 'EMAIL' | translate }}">
                    </div>
                    <div class="mb-3">
                        <input type="password" class="form-control custom-input" name="password"
                            [(ngModel)]="form.password" placeholder="{{ 'PASSWORD' | translate }}">
                    </div>
                    <div class="mb-3">
                        <label class="form-check-label" for="flexCheckDefault">
                            <input [checked]="saveInfos" (click)="changeSaveInfos()" class="form-check-input"
                                type="checkbox" id="flexCheckDefault">
                            {{ 'SAVE_INFO' | translate }}
                        </label>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-primary">{{ 'LOGIN_BUTTON' | translate }}</button>
                    </div>
                </form>
                }
                <a href="/registration">{{ 'REGISTER_PROMPT' | translate }}</a>
                <a href="/forgot-password">{{ 'FORGOT_PASSWORD' | translate }}</a>


            </div>
        </div>
    </main>
</div>