import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ItemsComponent } from '../../cources/items/items.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recommendations',
  standalone: true,
  imports: [ItemsComponent, TranslateModule],
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss']
})
export class RecommendationsComponent implements OnInit {
  category = '';
  cources: any[] = [];

  constructor(private translate: TranslateService, private http: HttpClient) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.search(); // Chargement initial des cours
  }

  // Méthode commune pour récupérer les recommandations
  getRecommendations(category: string) {
    const apiUrl = 'https://soacwaaslearning.studiolab.fr/elearning/course/getRecommendation';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let params = new HttpParams().set('category', category);

    this.http.get(apiUrl, { params, headers }).subscribe(
      (result: any) => {
        this.cources = result.content.slice(0, 4); // Limiter l'affichage à 4 cours
      },
      (error) => {
        console.error('Erreur lors de la récupération des cours:', error); // Gestion des erreurs
      }
    );
  }

  // Recherche initiale
  search() {
    this.getRecommendations(this.category); // Appel de la méthode commune
  }

  // Changement de catégorie
  changeCategory(e: any) {
    this.category = e.target.value;
    this.getRecommendations(this.category); // Appel de la méthode commune avec la nouvelle catégorie
  }
}
