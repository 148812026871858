<div class="attestation" id="test">
    <img src="assets/attestation2.png" alt="Attestation" class="fullscreen-image" />
    <p id="nom">{{user?.last_name +" " +  user?.name}}</p>
    <p id="jour-fin">de  {{today | date}}</p>
    <p id="theme">{{cource.name}}</p>
    <!-- Grouping the date elements within a container div -->
    <div id="date-container">
          Abidjant le ,
          {{dd}} /
        {{mm}} /
        {{yyyy}}
    </div>
    <p id="nom-formateur1">{{cource.fourmateur1}}</p>
    <p id="nom-formateur2">{{cource.fourmateur2}}</p>
    <img class="qrCode" src="https://cdn-icons-png.flaticon.com/512/14021/14021433.png"/>
</div>
<div>
    <button (click)="downloadPDF()" id="pdf">Imprimer en PDF</button>
</div>
