<div class="p-md-5">
    <h1 class="mb-5 mt-5">
        {{'Pourquoi_une_formation_semsoc' | translate}}
    </h1>

    <div class="row m-4">
        <div class="col-md-4 ">
            <div class="b-card m-2 p-md-4 mb-5">
                <div class="green-squar m-4">
                    <h3>1</h3>
                    <span class="green-squar-strip"></span>
                </div>
                <h2 class="m-4">
                    {{'Plusieurs_thematiques_de_formation' | translate}}
                </h2>
                <p class="m-4">
                    {{'Plusieurs_thematiques_de_formation_description' | translate}}
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="b-card m-2 p-md-4 mb-4">
                <div class="green-squar m-4">
                    <h3>2</h3>
                    <span class="green-squar-strip"></span>
                </div>
                <h2 class="m-4">
                    {{'Acces_sur_un_an' | translate}}
                </h2>
                <p class="m-4">
                    {{'Acces_sur_un_an_description' | translate}}
                </p>
            </div>
        </div>
        <div class="col-md-4 ">
            <div class="b-card m-2 p-md-4 mb-4">
                <div class="green-squar m-4">
                    <h3>3</h3>
                    <span class="green-squar-strip"></span>
                </div>
                <h2 class="m-4">
                    {{'Des_formations_selon_vos_besoins' | translate}}
                </h2>
                <p class="m-4">
                    {{'Des_formations_selon_vos_besoins_description' | translate}}
                </p>
            </div>
        </div>
        <div class="col-md-4 ">
            <div class="b-card m-2 p-md-4 mb-4">
                <div class="green-squar m-4">
                    <h3>4</h3>
                    <span class="green-squar-strip"></span>
                </div>
                <h2 class="m-4">
                    {{'Certificats_et_portfolios' | translate}}
                </h2>
                <p class="m-4">
                    {{'Certificats_et_portfolios_description' | translate}}
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="b-card m-2 p-md-4 mb-4">
                <div class="green-squar m-4">
                    <h3>5</h3>
                    <span class="green-squar-strip"></span>
                </div>
                <h2 class="m-4">
                    {{'Apprentissage_plus_dirige' | translate}}
                </h2>
                <p class="m-4">
                    {{'Apprentissage_plus_dirige_description' | translate}}
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="b-card m-2 p-md-4 mb-4">
                <div class="green-squar m-4">
                    <h3>6</h3>
                    <span class="green-squar-strip"></span>
                </div>
                <h2 class="m-4">
                    {{'Des_formateurs_experimentes' | translate}}
                </h2>
                <p class="m-4">
                    {{'Des_formateurs_experimentes_description' | translate}}
                </p>
            </div>
        </div>
    </div>
</div>
