import { Component, Input, OnInit } from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs';
import { FaqListComponent } from '../faq-list/faq-list.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from 'express';

@Component({
  selector: 'app-content',
  standalone: true,
  imports: [FaqListComponent,MatTabsModule,TranslateModule],
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss'
})
export class ContentComponent  implements OnInit{



  constructor(private translate: TranslateService) {

  }

  @Input() testimonials:string=""
  @Input() teachingMethod:string=""
  @Input() precondition:string=""
  @Input() syllabus:string=""
  @Input() instructor:string=""
  @Input() description:string=""
  @Input() faqList:any[]=[]

  ngOnInit(): void {
 

    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");

      }

    } catch (error) {
      this.translate.setDefaultLang('fr');

    }


  }
  
  changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
  }




  

}
