<div class="counters-wrapper">
    <div class="counters-content">
        <div class="counter-block marquee">
            <img width="200" src="assets/bannière[1].png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/bannière[1].png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/bannière[1].png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/bannière[1].png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/bannière[1].png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/bannière[1].png">
        </div>

        <!-- <div class="counter-block marquee">
            <img width="200" src="assets/cedeao.png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/LOGO_ECOWAQ.png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/logo_uemoa.jpg">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/onudi.png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/stdf_logo (1).jpg">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/UE.jpg">
        </div>

        <div class="counter-block marquee">
            <img width="200" src="assets/cedeao.png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/LOGO_ECOWAQ.png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/logo_uemoa.jpg">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/onudi.png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/stdf_logo (1).jpg">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/UE.jpg">
        </div>

        <div class="counter-block marquee">
            <img width="200" src="assets/cedeao.png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/LOGO_ECOWAQ.png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/logo_uemoa.jpg">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/onudi.png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/stdf_logo (1).jpg">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/UE.jpg">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/UE.jpg">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/cedeao.png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/LOGO_ECOWAQ.png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/logo_uemoa.jpg">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/onudi.png">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/stdf_logo (1).jpg">
        </div>
        <div class="counter-block marquee">
            <img width="200" src="assets/UE.jpg">
        </div> -->

    </div>
</div>