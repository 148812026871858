import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StorageServiceService } from '../../../storage.service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var paypal: any;

@Component({
  selector: 'app-payment-form',
  standalone: true,
  imports: [TranslateModule,
    MatButtonModule, MatMenuModule, MatIconModule],
  templateUrl: './payment-form.component.html',
  styleUrl: './payment-form.component.scss'
})
export class PaymentFormComponent implements OnInit {

  @Input() cource: any
  constructor(private route: ActivatedRoute, private _snackBar: MatSnackBar, private http: HttpClient, private storageService: StorageServiceService, private router: Router, private translate: TranslateService) {
    translate.addLangs(['en', 'fr']);

    translate.setDefaultLang('en');
  }

  navigateToAbout() {
    // this.router.navigate(['/learning/course/' + this.route.snapshot.paramMap.get('id')]);
    window.location.href='/learning/course/' + this.route.snapshot.paramMap.get('id')
  }

  ngOnInit(): void {
    paypal.Buttons({
      createOrder: (data: any, actions: any) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: '100.00' // Example amount
            }
          }]
        });
      },
      onApprove: (data: any, actions: any) => {
        return actions.order.capture().then((details: any) => {
          console.log(this.cource,":::success :: ", details);
          const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/subcribe"
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `${this.storageService.getUser().tokenType} ${this.storageService.getUser().accessToken}`
          });

          let data1 = {
            "idCource": this.route.snapshot.paramMap.get('id'),
            "courceName": this.cource.name,
            "leassonsIds": [],
            "progress": "0",
            "passingScore": this.cource.passingScore
          }

          this.http.post(apiUrl, data1, { headers }).subscribe((result: any) => {

            this.openSnackBar(result.message)

            setTimeout(() => {
              this.navigateToAbout()
            }, 2000);
          })
        });
      }
    }).render('#paypal-button-container');
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "", {
      duration: 3000
    });
  }

}
