<!-- <app-nav></app-nav>
<app-path></app-path> -->


<div class="language-switcher">
    <span class="d-flex">
        <div class="d-flex px-md-5">
            <button mat-icon-button [matMenuTriggerFor]="menulang">
                <img style="width: 30px; height: 30px;" [src]="getFlag()" alt="{{'AVATAR' | translate}}">
            </button>
            <button mat-icon-button [matMenuTriggerFor]="menulang">
                <mat-icon>expand_more</mat-icon>
            </button>
        </div>

        <mat-menu #menulang="matMenu">
            <button mat-menu-item (click)="changeLanguage(getDiffLangAp())">
                <span><img style="width: 35px; height: 35px;" [src]="getDiffFlag()"> {{getDiffLang()}}</span>
            </button>
        </mat-menu>
    </span>
</div>
<div class="p-md-5">
    

   


    <!-- <pre> {{questions | json}} </pre> -->
    <app-exam-description></app-exam-description>

    @for( q of questions; track q.question.id){
        @if(q.question.questionType=='writin'){
            <app-question-type1  [questionNumber]="$index"  [qt]="q"   ></app-question-type1>
        } @else if(q.question.questionType=='single answer'){
            <app-question-type3  [questionNumber]="$index"  [qt]="q"   ></app-question-type3>
        } @else{
            <app-question-type4  [questionNumber]="$index"  [qt]="q"   ></app-question-type4>
        }
    }
    <!-- <app-question-type2></app-question-type2> -->
    <div class="row"  >
            <button (click)="checkScore()" class="btn d-block btn-warning col-md-12" > {{ 'submit' | translate }}  </button>
    </div>
</div>
