<div class="p-5" id="id_cours">
    <div class="row m-5">
      <div class="col-md-4">
        <h2>{{ 'Our_recommended_semsoc_themes_for_you' | translate }}</h2>
      </div>
      <div class="col-md-8 d-flex flex-column justify-content-between">
        <div class="row d-flex justify-content-end">
          <select
            style="border: 1px solid black; border-radius: 5px;"
            (change)="changeCategory($event)"
            id="exampleSelect"
          >
            <option value="">{{ 'ALL_CATEGORIES' | translate }}</option>
            <option value="TRAINING FOR TESTING AND ANALYSIS LABORATORIES">
              {{ 'TRAINING FOR TESTING AND ANALYSIS LABORATORIES' | translate }}
            </option>
            <option value="TRAINING FOR LABORATORIES">
              {{ 'TRAINING FOR LABORATORIES' | translate }}
            </option>
            <option value="TRAINING FOR MEDICAL BIOLOGY LABORATORIES">
              {{ 'TRAINING FOR MEDICAL BIOLOGY LABORATORIES' | translate }}
            </option>
            <option value="TRAINING FOR CERTIFICATION BODIES">
              {{ 'TRAINING FOR CERTIFICATION BODIES' | translate }}
            </option>
            <option value="TRAINING FOR PROFICIENCY TESTING BODIES">
              {{ 'TRAINING FOR PROFICIENCY TESTING BODIES' | translate }}
            </option>
            <option value="TRAINING FOR VERIFICATION AND VALIDATION BODIES">
              {{ 'TRAINING FOR VERIFICATION AND VALIDATION BODIES' | translate }}
            </option>
          </select>
          <button (click)="search()" class="btn">
            {{ 'VIEW_ALL' | translate }}
          </button>
        </div>
      </div>
    </div>
    <hr />
    <app-items [cources]="cources"></app-items>
  </div>
  