<div class="container p-md-5">
    <a href="" style="padding-bottom: 20px;">
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 7.99992H17.5M8.33333 3.33325L2.5 7.99992L8.33333 3.33325ZM2.5 7.99992L8.33333 12.6666L2.5 7.99992Z"
                stroke="#282938" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        {{ 'HOMEPAGE' | translate }}
    </a>
    <div class="language-switcher">
        <span class="d-flex">
            <div class="d-flex px-md-5">
                <button mat-icon-button [matMenuTriggerFor]="menulang">
                    <img style="width: 30px; height: 30px;" [src]="getFlag()" alt="{{'AVATAR' | translate}}">
                </button>
                <button mat-icon-button [matMenuTriggerFor]="menulang">
                    <mat-icon>expand_more</mat-icon>
                </button>
            </div>

            <mat-menu #menulang="matMenu">
                <button mat-menu-item (click)="changeLanguage(getDiffLangAp())">
                    <span><img style="width: 35px; height: 35px;" [src]="getDiffFlag()"> {{getDiffLang()}}</span>
                </button>
            </mat-menu>
        </span>
    </div>

 
    <main class="row">
        <div class="col-md-6">
            <div>
                <h1>{{ 'CHECK_EMAIL_TITLE' | translate }}</h1>
                <p>{{ 'CHECK_EMAIL_DESCRIPTION' | translate }}</p>
            </div>
            <img src="assets/8878.png">
        </div>
        <div class="col-md-6">
            <div class="content-page">
                <img src="assets/Featured icon (2).png">
                <h2>{{ 'CHECK_EMAIL_TITLE' | translate }}</h2>
                <p>{{ 'CHECK_EMAIL_DESCRIPTION' | translate }}</p>
                <button (click)="navigateToAbout()">
                    {{ 'OPEN_EMAIL_APP' | translate }}
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 18.3334C15.1023 18.3334 18.8333 14.6024 18.8333 10C18.8333 5.39765 15.1023 1.66669 10.5 1.66669C5.89759 1.66669 2.16663 5.39765 2.16663 10C2.16663 14.6024 5.89759 18.3334 10.5 18.3334Z"
                            stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>
                <a href="">
                    {{ 'DID_NOT_RECEIVE' | translate }} <span>{{ 'CLICK_TO_RESEND' | translate }}</span>
                </a>
                <a href="">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.8334 6.99996H1.16675M1.16675 6.99996L7.00008 12.8333M1.16675 6.99996L7.00008 1.16663"
                            stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    {{ 'BACK_TO_LOGIN' | translate }}
                </a>
            </div>
        </div>
    </main>
</div>
