<div class="p-5 ">
    <mat-button-toggle-group class="m-5" #viewToggle="matButtonToggleGroup" aria-label="View toggle">
        <mat-button-toggle (click)="changeShowCard(false)" checked value="grid">
            <mat-icon>grid_on</mat-icon> {{'Grid' | translate}}
        </mat-button-toggle>
        <!-- <mat-button-toggle (click)="changeShowCard(true)" value="list">
            <mat-icon>view_list</mat-icon> {{'List' | translate}}
        </mat-button-toggle> -->
    </mat-button-toggle-group>


    <div [hidden]="showCards" class="row">


        @for(item of cources ; track item.id){
            <div class="col-md-3  p-md-2 recommendation">
                <div class="recommendation-header">
                    <img (click)="navigateToAbout(item.id)" [src]="item.picture">
                    <!-- <span>{{item.price }} €</span> -->
                </div>
                <div class="recommendation-body mt-4 p-3">
                    <div>
                        <h3 (click)="navigateToAbout(item.id)">
                            {{item.name }}
                        </h3>
                        <p>
                            {{item.briefing }}
                        </p>
                    </div>

                    <!-- <div class="d-flex justify-content-between">
                        <span>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.8">
                                    <path
                                        d="M12 8.93335V12.9333L15 15.9333L12 8.93335ZM21 12.9333C21 14.1152 20.7672 15.2856 20.3149 16.3775C19.8626 17.4694 19.1997 18.4616 18.364 19.2973C17.5282 20.133 16.5361 20.796 15.4442 21.2483C14.3522 21.7006 13.1819 21.9333 12 21.9333C10.8181 21.9333 9.64778 21.7006 8.55585 21.2483C7.46392 20.796 6.47177 20.133 5.63604 19.2973C4.80031 18.4616 4.13738 17.4694 3.68508 16.3775C3.23279 15.2856 3 14.1152 3 12.9333C3 10.5464 3.94821 8.25722 5.63604 6.56939C7.32387 4.88156 9.61305 3.93335 12 3.93335C14.3869 3.93335 16.6761 4.88156 18.364 6.56939C20.0518 8.25722 21 10.5464 21 12.9333Z"
                                        stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                            </svg>
                            {{(item.totalHourses-(item.totalHourses % 60)) / 60 }} min
                        </span>
                        <span>
                            {{item.countVideos }} Video
                        </span>
                        <span>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.8">
                                    <path
                                        d="M10 4.56168C10.4479 4.05394 11.0397 3.69461 11.6968 3.53151C12.354 3.36842 13.0451 3.40929 13.6785 3.64868C14.3118 3.88808 14.8572 4.31466 15.2421 4.87165C15.627 5.42864 15.8332 6.08964 15.8332 6.76668C15.8332 7.44373 15.627 8.10473 15.2421 8.66172C14.8572 9.21871 14.3118 9.64528 13.6785 9.88468C13.0451 10.1241 12.354 10.165 11.6968 10.0019C11.0397 9.83876 10.4479 9.47943 10 8.97168V4.56168ZM12.5 18.4333H2.5V17.6C2.5 16.2739 3.02678 15.0022 3.96447 14.0645C4.90215 13.1268 6.17392 12.6 7.5 12.6C8.82608 12.6 10.0979 13.1268 11.0355 14.0645C11.9732 15.0022 12.5 16.2739 12.5 17.6V18.4333ZM12.5 18.4333H17.5V17.6C17.5001 16.7223 17.2692 15.86 16.8304 15.0998C16.3916 14.3396 15.7604 13.7083 15.0003 13.2694C14.2402 12.8304 13.3779 12.5993 12.5002 12.5993C11.6224 12.5993 10.7601 12.8303 10 13.2692L12.5 18.4333ZM10.8333 6.76668C10.8333 7.65074 10.4821 8.49859 9.85702 9.12371C9.2319 9.74883 8.38405 10.1 7.5 10.1C6.61594 10.1 5.7681 9.74883 5.14298 9.12371C4.51786 8.49859 4.16667 7.65074 4.16667 6.76668C4.16667 5.88263 4.51786 5.03478 5.14298 4.40966C5.7681 3.78454 6.61594 3.43335 7.5 3.43335C8.38405 3.43335 9.2319 3.78454 9.85702 4.40966C10.4821 5.03478 10.8333 5.88263 10.8333 6.76668V6.76668Z"
                                        stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                            </svg>
                            {{item.countUsers }} {{'Siswa' | translate}}
                        </span>
                    </div> -->
                </div>


            </div>


        }@empty {

            <div class="container p-md-4">
                <div class=" skeleton ">
                    <div class="skeleton-left flex1">
                        <div class="square"></div>
                    </div>
                    <div class="skeleton-right flex2">
                        <div class="line h17 w40 m10"></div>
                        <div class="line"></div>
                        <div class="line h8 w50"></div>
                        <div class="line  w75"></div>
                    </div>
                </div>
            </div>
        }




    </div>
    <div [hidden]="!showCards" class="p-md-4 recommendation-lists">
        @for(item of cources ; track item.id){
        <div class="recommendation-list p-md-4 m-md-4 row">
            <div class="col-md-4 ">
                <img style="cursor: pointer;" (click)="navigateToAbout(item.id)" [src]="item.picture">
            </div>
            <div class="col-md-8 ">
                <div class="recommendation-body ">
                    <div class="d-flex justify-content-between">
                        <h3 (click)="navigateToAbout(item.id)">
                            {{item.name }}
                        </h3>
                        <!-- <div class="price">{{item.price }} €</div> -->
                    </div>

                    <p>
                        {{item.briefing }}
                    </p>
                    <!-- <div class="d-flex justify-content-start">
                        <span>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.8">
                                    <path
                                        d="M12 8.93335V12.9333L15 15.9333L12 8.93335ZM21 12.9333C21 14.1152 20.7672 15.2856 20.3149 16.3775C19.8626 17.4694 19.1997 18.4616 18.364 19.2973C17.5282 20.133 16.5361 20.796 15.4442 21.2483C14.3522 21.7006 13.1819 21.9333 12 21.9333C10.8181 21.9333 9.64778 21.7006 8.55585 21.2483C7.46392 20.796 6.47177 20.133 5.63604 19.2973C4.80031 18.4616 4.13738 17.4694 3.68508 16.3775C3.23279 15.2856 3 14.1152 3 12.9333C3 10.5464 3.94821 8.25722 5.63604 6.56939C7.32387 4.88156 9.61305 3.93335 12 3.93335C14.3869 3.93335 16.6761 4.88156 18.364 6.56939C20.0518 8.25722 21 10.5464 21 12.9333Z"
                                        stroke="black" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </g>
                            </svg>
                            {{(item.totalHourses-(item.totalHourses % 60)) / 60 }} min
                        </span>
                        <span>
                            {{item.countVideos }} Video
                        </span>
                        <span>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.8">
                                    <path
                                        d="M10 4.56168C10.4479 4.05394 11.0397 3.69461 11.6968 3.53151C12.354 3.36842 13.0451 3.40929 13.6785 3.64868C14.3118 3.88808 14.8572 4.31466 15.2421 4.87165C15.627 5.42864 15.8332 6.08964 15.8332 6.76668C15.8332 7.44373 15.627 8.10473 15.2421 8.66172C14.8572 9.21871 14.3118 9.64528 13.6785 9.88468C13.0451 10.1241 12.354 10.165 11.6968 10.0019C11.0397 9.83876 10.4479 9.47943 10 8.97168V4.56168ZM12.5 18.4333H2.5V17.6C2.5 16.2739 3.02678 15.0022 3.96447 14.0645C4.90215 13.1268 6.17392 12.6 7.5 12.6C8.82608 12.6 10.0979 13.1268 11.0355 14.0645C11.9732 15.0022 12.5 16.2739 12.5 17.6V18.4333ZM12.5 18.4333H17.5V17.6C17.5001 16.7223 17.2692 15.86 16.8304 15.0998C16.3916 14.3396 15.7604 13.7083 15.0003 13.2694C14.2402 12.8304 13.3779 12.5993 12.5002 12.5993C11.6224 12.5993 10.7601 12.8303 10 13.2692L12.5 18.4333ZM10.8333 6.76668C10.8333 7.65074 10.4821 8.49859 9.85702 9.12371C9.2319 9.74883 8.38405 10.1 7.5 10.1C6.61594 10.1 5.7681 9.74883 5.14298 9.12371C4.51786 8.49859 4.16667 7.65074 4.16667 6.76668C4.16667 5.88263 4.51786 5.03478 5.14298 4.40966C5.7681 3.78454 6.61594 3.43335 7.5 3.43335C8.38405 3.43335 9.2319 3.78454 9.85702 4.40966C10.4821 5.03478 10.8333 5.88263 10.8333 6.76668V6.76668Z"
                                        stroke="black" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </g>
                            </svg>
                            {{item.countUsers }} {{'Siswa' | translate}}
                        </span>
                    </div> -->
                </div>
            </div>
        </div>


        <div class="recommendation-list p-md-4 m-md-4 row">
            <div class="col-md-4 ">
                <img style="cursor: pointer;" (click)="navigateToAbout(item.id)" [src]="item.picture">
            </div>
            <div class="col-md-8 ">
                <div class="recommendation-body ">
                    <div class="d-flex justify-content-between">
                        <h3 (click)="navigateToAbout(item.id)">
                            {{item.name }}
                        </h3>
                        <!-- <div class="price">{{item.price }} €</div> -->
                    </div>

                    <p>
                        {{item.briefing }}
                    </p>
                    <!-- <div class="d-flex justify-content-start">
                        <span>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.8">
                                    <path
                                        d="M12 8.93335V12.9333L15 15.9333L12 8.93335ZM21 12.9333C21 14.1152 20.7672 15.2856 20.3149 16.3775C19.8626 17.4694 19.1997 18.4616 18.364 19.2973C17.5282 20.133 16.5361 20.796 15.4442 21.2483C14.3522 21.7006 13.1819 21.9333 12 21.9333C10.8181 21.9333 9.64778 21.7006 8.55585 21.2483C7.46392 20.796 6.47177 20.133 5.63604 19.2973C4.80031 18.4616 4.13738 17.4694 3.68508 16.3775C3.23279 15.2856 3 14.1152 3 12.9333C3 10.5464 3.94821 8.25722 5.63604 6.56939C7.32387 4.88156 9.61305 3.93335 12 3.93335C14.3869 3.93335 16.6761 4.88156 18.364 6.56939C20.0518 8.25722 21 10.5464 21 12.9333Z"
                                        stroke="black" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </g>
                            </svg>
                            {{(item.totalHourses-(item.totalHourses % 60)) / 60 }} min
                        </span>
                        <span>
                            {{item.countVideos }} Video
                        </span>
                        <span>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.8">
                                    <path
                                        d="M10 4.56168C10.4479 4.05394 11.0397 3.69461 11.6968 3.53151C12.354 3.36842 13.0451 3.40929 13.6785 3.64868C14.3118 3.88808 14.8572 4.31466 15.2421 4.87165C15.627 5.42864 15.8332 6.08964 15.8332 6.76668C15.8332 7.44373 15.627 8.10473 15.2421 8.66172C14.8572 9.21871 14.3118 9.64528 13.6785 9.88468C13.0451 10.1241 12.354 10.165 11.6968 10.0019C11.0397 9.83876 10.4479 9.47943 10 8.97168V4.56168ZM12.5 18.4333H2.5V17.6C2.5 16.2739 3.02678 15.0022 3.96447 14.0645C4.90215 13.1268 6.17392 12.6 7.5 12.6C8.82608 12.6 10.0979 13.1268 11.0355 14.0645C11.9732 15.0022 12.5 16.2739 12.5 17.6V18.4333ZM12.5 18.4333H17.5V17.6C17.5001 16.7223 17.2692 15.86 16.8304 15.0998C16.3916 14.3396 15.7604 13.7083 15.0003 13.2694C14.2402 12.8304 13.3779 12.5993 12.5002 12.5993C11.6224 12.5993 10.7601 12.8303 10 13.2692L12.5 18.4333ZM10.8333 6.76668C10.8333 7.65074 10.4821 8.49859 9.85702 9.12371C9.2319 9.74883 8.38405 10.1 7.5 10.1C6.61594 10.1 5.7681 9.74883 5.14298 9.12371C4.51786 8.49859 4.16667 7.65074 4.16667 6.76668C4.16667 5.88263 4.51786 5.03478 5.14298 4.40966C5.7681 3.78454 6.61594 3.43335 7.5 3.43335C8.38405 3.43335 9.2319 3.78454 9.85702 4.40966C10.4821 5.03478 10.8333 5.88263 10.8333 6.76668V6.76668Z"
                                        stroke="black" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </g>
                            </svg>
                            {{item.countUsers }} {{'Siswa' | translate}}
                        </span>
                    </div> -->
                </div>
            </div>
        </div>
        }@empty {

            <div class="container p-md-4">
                <div class=" skeleton ">
                    <div class="skeleton-left flex1">
                        <div class="square"></div>
                    </div>
                    <div class="skeleton-right flex2">
                        <div class="line h17 w40 m10"></div>
                        <div class="line"></div>
                        <div class="line h8 w50"></div>
                        <div class="line  w75"></div>
                    </div>
                </div>
            </div>
        }



    </div>




</div>