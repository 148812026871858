<div class="container-fluid p-md-5" id="id-obout">
    <div class="row m-4 align-items-center">
        <div class="col-md-7 col-sm-12 p-md-4 about-us-content">
            <h2>{{ 'ABOUT_US' | translate }}</h2>
            <p>{{ 'Soac_summary_description1' | translate }}</p>
            <ul>
                <li>{{ 'Soac_summary_list_1' | translate }}</li>
                <li>{{ 'Soac_summary_list_2' | translate }}</li>
                <li>{{ 'Soac_summary_list_3' | translate }}</li>
            </ul>
            <p>{{ 'Soac_summary_description2' | translate }}</p>
            <p>{{ 'Soac_summary_description3' | translate }}</p>
        </div>
        <div class="col-md-5 col-sm-12">
            <img src="assets/about.jpg" class="img-fluid" alt="About Us">
        </div>
    </div>
</div>
