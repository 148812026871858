import { Component, OnInit } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageServiceService } from '../../../storage.service.service';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
export interface TableElement {
  id:string;
  photo: string;
  title: string;
  description: string;
  progress: number;
  type: boolean;
}


@Component({
  selector: 'app-courses-progress',
  standalone: true,
  imports: [TranslateModule,MatInputModule,MatIconModule,MatSelectModule,MatTableModule,MatProgressBarModule,MatButtonModule],
  templateUrl: './courses-progress.component.html',
  styleUrl: './courses-progress.component.scss'
})
export class CoursesProgressComponent  implements OnInit{


  constructor(private translate: TranslateService,private router: Router, private http: HttpClient, private storageService: StorageServiceService){

    translate.addLangs(['en', 'fr']);

    translate.setDefaultLang('en');
  }
  cources:TableElement[]=[]


  searchWord=""
  category="all"
  courcesbySearch:TableElement[]=[]

  navigateToAbout(id:string) {
    this.router.navigate([`/attestation/${id}`]);
  }

  changeLanguage=(lang:string)=>{
    this.translate.setDefaultLang(lang)
  }

  realtimeSearch(e:any){
    this.searchWord=e.target.value

    this.courcesbySearch=this.cources.filter((el:TableElement)=>{return el.title.toLowerCase().includes(e.target.value)}).filter((el:TableElement)=>{
      if(this.category=="all"){
        return true
      }else if(this.category=="done"){
        return el.type==true
      }else{
        return el.type==false

      }

    })
  }

  realtimeCategory(e:any){
    console.log(e.value)
    this.category=e.value

    this.courcesbySearch=this.cources.filter((el:TableElement)=>{return el.title.toLowerCase().includes(this.searchWord)}).filter((el:TableElement)=>{
      if(e.value=="all"){
        return true
      }else if(e.value=="done"){
        return el.type==true
      }else{
        return el.type==false

      }

    })
  }


  ngOnInit(): void {
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/getCourceFromStudent"
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.storageService.getUser().tokenType} ${this.storageService.getUser().accessToken}`
    });

    this.http.get(apiUrl,  { headers }).subscribe((result:any) => {
      result.forEach((el:any)=>{
        const apiUrl1 = "https://soacwaaslearning.studiolab.fr/elearning/course/getSinglecource/"+el.idCource
        this.http.get(apiUrl1,  { headers }).subscribe((res:any) => {

          console.log("res::",res);

          this.cources.push({
            id:res.id,
            photo: res.picture,
            title: res.name,
            description: res.briefing,
            progress: el.progress,
            type: el.passed
          })
          this.courcesbySearch.push({
            id:res.id,
            photo: res.picture,
            title: res.name,
            description: res.briefing,
            progress: el.progress,
            type: el.passed
          })

        })
      })


    })

  }

  foods = [
    { value: 'all', viewValue: 'All' },
    { value: 'done', viewValue: 'Done' },
    { value: 'progress', viewValue: 'In Progress' }
  ];

}
