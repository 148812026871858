import { Component, OnInit } from '@angular/core';
import { NavComponent } from '../../compoments/commun/nav/nav.component';
import { MethosComponent } from '../../compoments/payment/methos/methos.component';
import { PaymentsInfosComponent } from '../../compoments/payment/payments-infos/payments-infos.component';
import { PaymentFormComponent } from '../../compoments/payment/payment-form/payment-form.component';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [NavComponent, MethosComponent, PaymentFormComponent, PaymentsInfosComponent],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss'
})
export class PaymentComponent implements OnInit {

  cource: any
  constructor(private http: HttpClient, private route: ActivatedRoute) { }



  name: string = ""
  description: string = ""
  category: string = ""
  videoUrl: string = ""
  picture: string = ""
  briefing: string = ""
  id: string = ""
  price: string = ""
  ngOnInit(): void {


    const apiUrl = `https://soacwaaslearning.studiolab.fr/elearning/course/getCource/${this.route.snapshot.paramMap.get('id')}`
    this.http.get(apiUrl).subscribe((result: any) => {
      this.name = result.name;
      this.category = result.category;
      this.videoUrl = result.videoUrl;
      this.briefing = result.briefing;
      this.picture = result.picture;
      this.price = result.price;
      this.id = result.id;
      this.description = result.description;

      this.cource = result

    })
  }

}
