import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payments-infos',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, MatIconModule, CommonModule, TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule],
  templateUrl: './payments-infos.component.html',
  styleUrl: './payments-infos.component.scss'
})
export class PaymentsInfosComponent {
  cource:any
  constructor( private translate: TranslateService,
    private http: HttpClient,private route: ActivatedRoute
  ) {

    translate.addLangs(['en', 'fr']);

    translate.setDefaultLang('en');

  }

  name: string = ""
  description: string = ""
  category: string = ""
  videoUrl: string = ""
  picture: string = ""
  briefing:string=""
  id:string=""
  price:string=""

  ngOnInit(): void {

    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");

      }

    } catch (error) {
      this.translate.setDefaultLang('fr');

    }
    const apiUrl = ` https://soacwaaslearning.studiolab.fr/elearning/course/getCource/${this.route.snapshot.paramMap.get('id')}`
    this.http.get(apiUrl).subscribe((result: any) => {
      this.name = result.name;
      this.category = result.category;
      this.videoUrl = result.videoUrl;
      this.briefing = result.briefing;
      this.picture = result.picture;
      this.price = result.price;
      this.id = result.id;

      this.description = result.description;



    })
  }




  changeLanguage=(lang:string)=>{
    this.translate.setDefaultLang(lang)
  }
}
