<div>
    <!-- <h2>
        {{category}}
    </h2>-->
    <h1>
        {{name}}
    </h1>
    <h3>
        {{briefing}}
    </h3>

<img [src]="picture">
    <!-- @if(videoUrl){
        <video [poster]='picture' width="100%" controls >
            <source [src]="videoUrl" type="video/mp4">
            {{ 'VIDEO_NOT_SUPPORTED' | translate }}

          </video>
    } -->
</div>