import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { StorageServiceService } from '../../storage.service.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NavbarLeassonComponent } from '../../compoments/leason/navbar-leasson/navbar-leasson.component';
import { SidebarAdminComponent } from '../../compoments/dashboard-admin/sidebar-admin/sidebar-admin.component';
import { UserService } from '../../Services/user.service';
import { AuthService } from '../../Services/auth.service';
import { AdminStatisticsComponent } from '../../compoments/dashboard-admin/admin-statistics/admin-statistics.component';

@Component({
  selector: 'app-dashboard-admin',
  standalone: true,
  imports: [AdminStatisticsComponent,RouterModule, MatSidenavModule, MatButtonModule, HttpClientModule, NavbarLeassonComponent, SidebarAdminComponent],
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss']
})
export class DashboardAdminComponent implements OnInit {
  totalUsers: number = 0;
  totalAssistants: number = 0;
  // Property to hold the total number of assistants
  totalStudents: number = 0;
  totalCours:number=0;
  totalCoursAttended:number=0;



  constructor(
    private storageService: StorageServiceService,
    private userService: UserService,
    private auth: AuthService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    try {
      if (['ROLE_ADMIN', 'ROLE_ASSISTANT'].indexOf(this.storageService.getUser().roles[0]) == -1) {
        window.location.href = "/";
      } else {

        this.fetchTotalUser();
        this.fetchTotalUser1();

        this.fetchTotalAssistants(); // Fetch the total number of assistants on init
        this.fetchTotalStudents(); // Fetch the total number of students on init
      }
    } catch (error) {
      window.location.href = "/";
    }
  }

  fetchTotalUsers(): void {
    this.userService.getTotalUsers().subscribe(
      (count: number) => {
        this.totalUsers = count;
      },
      (error) => {
        console.error('Error fetching total users', error);
      }
    );
  }

  fetchTotalUser(): void {
    this.http.get<number>('https://soacwaaslearning.studiolab.fr/elearning/course/count').subscribe(
      (count: any) => {
        this.totalCours = count;
      },
      (error) => {
        console.error('Error fetching total totalCours', error);
      }
    );
  }

  fetchTotalUser1(): void {
    console.log('count ')

    this.http.get<number>('https://soacwaaslearning.studiolab.fr/elearning/course/count2').subscribe(
      (count: any) => {
        this.totalCoursAttended = count;
      },
      (error) => {
        console.error('Error fetching total totalCoursAttended', error);
      }
    );
  }

  fetchTotalAssistants(): void {
    this.auth.getTotalAssistants().subscribe(
      (response: any) => {
        const message = response.message;
        const totalAssistants = parseInt(message.replace(/^\D+/g, '')); // Extract the number from the message
        if (!isNaN(totalAssistants)) {
          this.totalAssistants = totalAssistants;
        } else {
          console.error('Error parsing total assistants', message);
        }
      },
      (error) => {
        console.error('Error fetching total assistants', error);
      }
    );
  }

  fetchTotalStudents(): void {
    this.auth.getTotalStudents().subscribe(
      (response: any) => {
        const message = response.message;
        const totalStudents = parseInt(message.replace(/^\D+/g, '')); // Extract the number from the message
        if (!isNaN(totalStudents)) {
          this.totalStudents = totalStudents;
        } else {
          console.error('Error parsing total assistants', message);
        }
      },
      (error) => {
        console.error('Error fetching total assistants', error);
      }
    );
  }
}
