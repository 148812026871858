<div class="row">
  <div class="col-4"></div>
  <a class="col-8" href="dashboard-admin/assistant" style="padding-bottom: 20px; text-decoration: none;">
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 7.99992H17.5M8.33333 3.33325L2.5 7.99992L8.33333 3.33325ZM2.5 7.99992L8.33333 12.6666L2.5 7.99992Z"
        stroke="#282938" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    {{ 'HOMEPAGE' | translate }}
  </a>
</div>
<div class="container">


  <div class="row">

    <h2>{{ 'CREATE_ASSISTANT' | translate }}</h2>
    <form (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="username">{{ 'USERNAME' | translate }}</label>
        <input type="text" id="username" class="form-control" [(ngModel)]="form.username" name="username" required />
      </div>
      <div class="form-group">
        <label for="email">{{ 'EMAIL' | translate }}</label>
        <input type="email" id="email" class="form-control" [(ngModel)]="form.email" name="email" required />
      </div>
      <div class="form-group">
        <label for="password">{{ 'PASSWORD' | translate }}</label>
        <input type="password" id="password" class="form-control" [(ngModel)]="form.password" name="password"
          required />
      </div>
      <div class="form-group">
        <label for="name">{{ 'FIRST_NAME' | translate }}</label>
        <input type="text" id="name" class="form-control" [(ngModel)]="form.name" name="name" required />
      </div>
      <div class="form-group">
        <label for="last_name">{{ 'LAST_NAME' | translate }}</label>
        <input type="text" id="last_name" class="form-control" [(ngModel)]="form.last_name" name="last_name" required />
      </div>
      <div class="form-group">
        <label for="sexe">{{ 'GENDER' | translate }}</label>
        <select id="sexe" class="form-control" [(ngModel)]="form.sexe" name="sexe" required>
          <option value="" disabled selected>{{ 'SELECT_GENDER' | translate }}</option>
          <option value="male">{{ 'MALE' | translate }}</option>
          <option value="female">{{ 'FEMALE' | translate }}</option>
          <option value="other">{{ 'OTHER' | translate }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="number_phone">{{ 'PHONE_NUMBER' | translate }}</label>
        <input type="text" id="number_phone" class="form-control" [(ngModel)]="form.number_phone" name="number_phone"
          required />
      </div>
      <div class="form-group">
        <label for="profileImageFile">{{ 'PROFILE_IMAGE' | translate }}</label>
        <input type="file" id="profileImageFile" class="form-control" (change)="onProfileImageFileChange($event)"
          name="profileImageFile" required />
      </div>
      @if(isSignUpFailed){
      <div class="alert alert-danger">
        {{ errorMessage }}
      </div>
      }

      <button type="submit" class="btn btn-primary">{{ 'CREATE_ASSISTANT_BUTTON' | translate }}</button>
    </form>
  </div>
</div>