import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { SearchComponent } from '../compoments/cources/search/search.component';
import { PaginationComponent } from '../compoments/commun/pagination/pagination.component';
import { ItemsComponent } from '../compoments/cources/items/items.component';
import { HttpClient, HttpClientModule, HttpHeaders, HttpParams } from '@angular/common/http';
import { YesOrNoComponent } from '../compoments/commun/yes-or-no/yes-or-no.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Page } from '../models/page.model';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-assistantes',
  standalone: true,
  imports: [MatProgressSpinnerModule,TranslateModule,RouterModule,HttpClientModule, SearchComponent, ItemsComponent, PaginationComponent,YesOrNoComponent,MatIconModule,MatButtonModule,



    CommonModule,
  ],
  templateUrl: './assistantes.component.html',
  styleUrl: './assistantes.component.scss'
})
export class AssistantesComponent {

  constructor(private translate: TranslateService,private _snackBar: MatSnackBar,private route: ActivatedRoute,private http: HttpClient ,public dialog: MatDialog ,private router: Router) {


   }
  name = ""
  username = ""
  number_phone=""

  pageNumber = 1
  pageSize = 2
  pages = ["1"]
  currentPage!: Page<any>
  ngOnInit(): void {
    this.translate.addLangs(['en', 'fr']);

    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      }else{
        this.translate.setDefaultLang('fr');
      }

    } catch (error) {


    }
    this.search()
  }

  goToPage=(t:number)=>{
    this.pageNumber = t
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/api/users/searchUsersByRole"

    let params = new HttpParams()
      .set('name', this.name)
      .set('username', this.username)
      .set('pageNumber', (t - 1))
      .set('pageSize', this.pageSize);
    this.http.get<any>(`${apiUrl}`, { params, headers }).subscribe(data => {
      this.currentPage = data
      this.getPaginationButtons(data.totalPages,t)
    })
  }

  changeLanguage=(lang:string)=>{
    this.translate.setDefaultLang(lang)
  }

  searchbyWord=(s:string)=>{
    this.name=s
    this.pageNumber=1
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/api/users/searchUsersByRole"

    let params = new HttpParams()
      .set('name',s)
      .set('username', this.username)
      .set('pageNumber', ( 0).toString())
      .set('pageSize', this.pageSize);
    this.http.get<any>(`${apiUrl}`, { params, headers }).subscribe(data => {
      this.currentPage = data
      this.getPaginationButtons(data.totalPages,this.pageNumber)
    })
  }

  searchbyCategory=(c:string)=>{
    this.username=c
    this.pageNumber=1

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/api/users/searchUsersByRole"

    let params = new HttpParams()
      .set('name',this.name)
      .set('username', c)
      .set('pageNumber', (0).toString())
      .set('pageSize', this.pageSize);
    this.http.get<any>(`${apiUrl}`, { params, headers }).subscribe(data => {
      this.currentPage = data
      this.getPaginationButtons(data.totalPages,this.pageNumber)
    })
  }


  search() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/api/users/searchUsersByRole"

    let params = new HttpParams()
      .set('name', this.name)
      .set('username', this.username)
      .set('pageNumber', (this.pageNumber - 1).toString())
      .set('pageSize', this.pageSize.toString());
    this.http.get<any>(`${apiUrl}`, { params, headers }).subscribe(data => {
      this.currentPage = data
      this.getPaginationButtons(data.totalPages,this.pageNumber)

    })

  }

  getPaginationButtons(totalPages: number, currentPage: number) {
    const paginationButtons = [];
    const delta = 2; // Number of buttons to show on each side of the current page
    const left = currentPage - delta;
    const right = currentPage + delta;

    // Ensure the currentPage is within the valid range
    currentPage = Math.max(1, Math.min(currentPage, totalPages));

    // Generate the pagination buttons
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= left && i <= right)) {
        paginationButtons.push(i.toString());
      } else if (i === left - 1 || i === right + 1) {
        paginationButtons.push("...");
      }
    }

    // Remove duplicate ellipses
    this.pages = paginationButtons.filter((item, pos, arr) =>
      item !== "..." || (pos > 0 && arr[pos - 1] !== "...")
    );
  }


  openDialog(idUser: number): void {
    const dialogRef = this.dialog.open(YesOrNoComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteQuestion(idUser)
      } else {
        // alert("no")
      }
    });
  }


  deleteQuestion(idCource: number) {
    const apiUrl = `https://soacwaaslearning.studiolab.fr/elearning/api/users/${idCource}`;
    this.http.delete(apiUrl).subscribe(result => {
      this.translate.get('QUESTION_DELETED_SUCCESS').subscribe((translatedMessage: string) => {
        this.openSnackBar(translatedMessage);
      });
      window.location.reload();
    });
  }
  

  openSnackBar(message: string) {
    this._snackBar.open(message,"",{
      duration: 3000
    });
  }

  createCource() {
    this.router.navigate(['dashboard-admin/create-assitant']);
  }
  updateCource(id:number) {
    this.router.navigate([ `dashboard-admin/edit-assistant/${id}`  ]);
  }
  credateLeasson(id:number) {
    this.router.navigate([ `dashboard-admin/create-leasson/${id}`  ]);
  }}