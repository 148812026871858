import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Page } from '../models/page.model';
import { Benefit } from '../Models/benefit';
import { BenefitService } from '../Services/benefit.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import Swal from 'sweetalert2';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-benefitss',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatButtonModule, MatSidenavModule, MatIconModule, MatMenuModule,
  ],
  templateUrl: './benefitss.component.html',
  styleUrl: './benefitss.component.scss',
  animations: [
    trigger('cardAnimation', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('0.5s', style({ opacity: 1 })),
        ]),
    ]),
],
})
export class BenefitssComponent implements OnInit {
  benefits: Benefit[] = [];

  constructor(private benefitService: BenefitService, private translate: TranslateService,private router: Router) { }

  ngOnInit(): void {
    this.benefitService.getAllBenefits().subscribe({
      next: (data: Benefit[]) => {
        this.benefits = data;
      },
      error: (err) => {
        console.error('Error fetching benefits', err);
      }
    });
  }
  deleteBenefit(benefitId: number) {
    Swal.fire({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to delete this benefit?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.benefitService.deleteBenefit(benefitId).subscribe({
          next: () => {
            // Remove the deleted benefit from the benefits array
            this.benefits = this.benefits.filter(benefit => benefit.id !== benefitId);
            Swal.fire(
              'Deleted!',
              'The benefit has been deleted.',
              'success'
            );
          },
          error: (err) => {
            console.error('Error deleting benefit:', err);
            Swal.fire(
              'Error!',
              'An error occurred while deleting the benefit.',
              'error'
            );
          }
        });
      }
    });
  }
  
  goToAddPage() {
    this.router.navigate(['dashboard-admin/add']); // Navigate to the 'add' route
}
}




