<mat-tab-group>
    <mat-tab [label]="'Description' | translate">
        <div class="container" [innerHtml]="description"></div>
    </mat-tab>
    <mat-tab [label]="'Instructor' | translate">
        <div class="container" [innerHtml]="instructor"></div>
    </mat-tab>
    <mat-tab [label]="'Syllabus' | translate">
        <div class="container" [innerHtml]="syllabus"></div>
    </mat-tab>
    <mat-tab [label]="'Precondition' | translate">
        <div class="container" [innerHtml]="precondition"></div>
    </mat-tab>
    <mat-tab [label]="'Teaching Method' | translate">
        <div class="container" [innerHtml]="teachingMethod"></div>
    </mat-tab>
    <mat-tab [label]="'Testimonials' | translate">
        <div class="container" [innerHtml]="testimonials"></div>
    </mat-tab>
    <mat-tab [label]="'Faq' | translate">
        <app-faq-list [faqList]="faqList"></app-faq-list>
    </mat-tab>
</mat-tab-group>
