import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BenefitService } from '../../Services/benefit.service';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import Swal from 'sweetalert2';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add',
  standalone: true,
  imports: [  TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatButtonModule, MatSidenavModule, MatIconModule, MatMenuModule,],
  templateUrl: './add.component.html',
  styleUrl: './add.component.scss'
})
export class AddComponent {
  benefitForm!: FormGroup;

  constructor(private translate: TranslateService,private formBuilder: FormBuilder, private benefitService: BenefitService,private router: Router) { }

  ngOnInit(): void {
    this.benefitForm = this.formBuilder.group({
      titre: ['', Validators.required], // Add validation if required
      description: ['', Validators.required] // Add validation if required
    });
  }

  onSubmit() {
    if (this.benefitForm.invalid) {
      return;
    }

    this.translate.get([
      'Confirm Creation',
      'Are you sure you want to create this benefit?',
      'Yes, create it!',
      'Cancel',
      'Created!',
      'The benefit has been created successfully.',
      'Error!',
      'An error occurred while creating the benefit.'
    ]).subscribe(translations => {
      Swal.fire({
        title: translations['Confirm Creation'],
        text: translations['Are you sure you want to create this benefit?'],
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: translations['Yes, create it!'],
        cancelButtonText: translations['Cancel']
      }).then((result) => {
        if (result.isConfirmed) {
          this.benefitService.createBenefit(this.benefitForm.value).subscribe({
            next: (data) => {
              console.log('Benefit created successfully:', data);
              this.router.navigate(['/benf']);
              Swal.fire(
                translations['Created!'],
                translations['The benefit has been created successfully.'],
                'success'
              );
            },
            error: (err) => {
              console.error('Error creating benefit:', err);
              Swal.fire(
                translations['Error!'],
                translations['An error occurred while creating the benefit.'],
                'error'
              );
            }
          });
        }
      });
    });
  }
}
