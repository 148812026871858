<div class="exam-description">
    <p class="date m-md-4">
        {{ 'date' | translate }}
    </p>
    <h1 class="m-md-4">
        {{ 'example' | translate }}
    </h1>

    <div class="decription p-md-4">
        <h2>{{ 'thankYou' | translate }}</h2>
        <p>
            {{ 'completeDocument' | translate }}
        </p>
    </div>
</div>
