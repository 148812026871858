import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { StorageServiceService } from '../../../storage.service.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sidebar-admin',
  standalone: true,
  imports: [TranslateModule,CommonModule ,MatSidenavModule],
  templateUrl: './sidebar-admin.component.html',
  styleUrl: './sidebar-admin.component.scss'
})
export class SidebarAdminComponent {
  isAssistance=false
  ngOnInit(): void {

    try {
      this.isAssistance=this.storageService.getUser().roles[0]=="ROLE_ASSISTANT"

    } catch (error) {

    }

    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");
      }else{
        this.translate.setDefaultLang('fr');
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }
  }
  goToHome() {
    this.router.navigateByUrl('/');
  }
  getActiveClass(path:string){
    return this.router.url==path
  }
  constructor(private storageService: StorageServiceService,private translate: TranslateService,private router: Router) { }
  navigateToAbout2() {
    this.router.navigate(['dashboard-admin/cources']);
  }
  navigateToAbout1() {
    this.router.navigate(['dashboard-admin/assistant']);
  }
    navigateToAbout3() {
    this.router.navigate(['dashboard-admin']);
  }
  navigateToAbout4() {
    this.router.navigate(['dashboard-admin/generalfaq']);
  }
  @Output() toggleSidenav = new EventEmitter<void>();
  showFiller = false;
}
