<div class=" d-flex justify-content-center pagination align-items-center">


    @for(bt of pages ; track bt){
        @if(bt=="..."){
            <div class="dots"> ... </div>

        }
        @if(bt==pageNumber){
            <button class="pg-btn active">
                {{pageNumber}}
            </button>

        }

        @if(bt!=pageNumber){
            @if(bt!="..."){
                <button style="background-color: #FFC825;"  (click)="goToPage(bt)" class="pg-btn">
                    {{bt}}
                </button>

            }
        }
    }



</div>