<div class="p-md-4">
    <div class="payment-infos">
        <div class="payment-infos-head d-flex justify-content-between align-items-center">
            <div class="payment-infos-price">

                {{price}}FCFA
            </div>
            <div class="payment-infos-name">      {{name}}</div>
        </div>
        <div class="payment-infos-body">
            <p>{{ briefing}}</p>
            <ul>
                <li>{{ 'feature_1' | translate }}</li>
                <li>{{ 'feature_2' | translate }}</li>
                <li>{{ 'feature_3' | translate }}</li>
            </ul>
        </div>
    </div>
</div>
