import { HttpClient, HttpHeaders, HttpParams, HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Page } from '../../../models/page.model';
import { SearchComponent } from '../../cources/search/search.component';
import { ItemsComponent } from '../../cources/items/items.component';
import { PaginationComponent } from '../../commun/pagination/pagination.component';
import { YesOrNoComponent } from '../../commun/yes-or-no/yes-or-no.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-cources-table',
  standalone: true,
  imports: [MatCardModule, MatProgressSpinnerModule, TranslateModule, RouterModule, HttpClientModule, SearchComponent, ItemsComponent, PaginationComponent, YesOrNoComponent, MatIconModule, MatButtonModule],
  templateUrl: './cources-table.component.html',
  styleUrl: './cources-table.component.scss'
})
export class CourcesTableComponent implements OnInit {
  constructor(private translate: TranslateService, private _snackBar: MatSnackBar, private route: ActivatedRoute, private http: HttpClient, public dialog: MatDialog, private router: Router) { }
  name = ""
  category = ""
  pageNumber = 1
  pageSize = 5
  pages = ["1"]
  currentPage!: Page<any>
  ngOnInit(): void {

    this.search()
    this.translate.addLangs(['en', 'fr']);

    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      } else {
        this.translate.setDefaultLang('fr');
      }

    } catch (error) {


    }
  }

  goToPage = (t: number) => {
    this.pageNumber = t
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/searchCources"

    let params = new HttpParams()
      .set('name', this.name)
      .set('category', this.category)
      .set('pageNumber', (t - 1))
      .set('pageSize', this.pageSize);
    this.http.get<any>(`${apiUrl}`, { params, headers }).subscribe(data => {
      this.currentPage = data
      this.getPaginationButtons(data.totalPages, t)

    })
  }

  changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
  }

  searchbyWord = (s: string) => {
    this.name = s
    this.pageNumber = 1
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/searchCources"

    let params = new HttpParams()
      .set('name', s)
      .set('category', this.category)
      .set('pageNumber', (0).toString())
      .set('pageSize', this.pageSize);
    this.http.get<any>(`${apiUrl}`, { params, headers }).subscribe(data => {
      this.currentPage = data
      this.getPaginationButtons(data.totalPages, this.pageNumber)

    })
  }

  searchbyCategory = (c: string) => {
    this.category = c
    this.pageNumber = 1

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/searchCources"

    let params = new HttpParams()
      .set('name', this.name)
      .set('category', c)
      .set('pageNumber', (0).toString())
      .set('pageSize', this.pageSize);
    this.http.get<any>(`${apiUrl}`, { params, headers }).subscribe(data => {
      this.currentPage = data
      this.getPaginationButtons(data.totalPages, this.pageNumber)

    })
  }


  search() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/searchCources"

    let params = new HttpParams()
      .set('name', this.name)
      .set('category', this.category)
      .set('pageNumber', (this.pageNumber - 1).toString())
      .set('pageSize', this.pageSize.toString());
    this.http.get<any>(`${apiUrl}`, { params, headers }).subscribe(data => {
      this.currentPage = data
      this.getPaginationButtons(data.totalPages, this.pageNumber)

    })

  }




  getPaginationButtons(totalPages: number, currentPage: number) {
    const paginationButtons = [];
    const delta = 2; // Number of buttons to show on each side of the current page
    const left = currentPage - delta;
    const right = currentPage + delta;

    // Ensure the currentPage is within the valid range
    currentPage = Math.max(1, Math.min(currentPage, totalPages));

    // Generate the pagination buttons
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= left && i <= right)) {
        paginationButtons.push(i.toString());
      } else if (i === left - 1 || i === right + 1) {
        paginationButtons.push("...");
      }
    }

    // Remove duplicate ellipses
    this.pages = paginationButtons.filter((item, pos, arr) =>
      item !== "..." || (pos > 0 && arr[pos - 1] !== "...")
    );
  }

  openDialog(idCource: number): void {
    const dialogRef = this.dialog.open(YesOrNoComponent);

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.deleteQuestion(idCource)
      } else {
        // alert("no")
      }
    });
  }

  deleteQuestion(idCourse: number) {
    const apiUrl = `https://soacwaaslearning.studiolab.fr/elearning/course/${idCourse}`;
    
    this.http.delete(apiUrl).subscribe(result => {
      const successMessage = this.translate.instant('question_deleted_successfully'); // Utilisation d'une clé de traduction
      
      this.openSnackBar(successMessage);
      
      // Optionnel : recharger la page pour mettre à jour les données
      window.location.reload();
    });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "", {
      duration: 3000
    });
  }

  createCource() {
    this.router.navigate(['dashboard-admin/create-cource']);
  }
  updateCource(id: number) {
    this.router.navigate([`dashboard-admin/cource-update/${id}`]);
  }
  credateLeasson(id: number) {
    this.router.navigate([`dashboard-admin/create-leasson/${id}`]);
  }

  updateExam(id: number) {
    this.router.navigate([`dashboard-admin/edit-exam/${id}`]);
  }

  updateFaq(id: number) {
    this.router.navigate([`dashboard-admin/edit-faq/${id}`]);
  }

}
