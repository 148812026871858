<div class="side-bar-leason">
    <div class="logo">

        <img (click)="goToHome()"  class="officialLogo" width="200" height="80" src="assets/SEMSOAC E LEARNING.png" >
    </div>
    @if(infos){
        <div class="progress-module">
            <h3>Statistics</h3>
            <div class="progress">
                <div class="progress-bar bg-warning" role="progressbar" [style.width]="infos.progress+'%'"
                    aria-valuemin="0" aria-valuemax="100">{{infos.progress}}%</div>
            </div>
            <span>
                {{videosCountWatched}} of the {{videosCount}} videos have been completed
            </span>
        </div>
    }


    @for(chapter of chapters; track chapter.chapterName){
    <div class="course-module">
        <h3>{{chapter.chapterName}}</h3>
        <ul>

            @for(leasson of chapter.leassons; track leasson.id){
            @if(leasson.active){
            <li class="leason active">
                {{leasson.name}}
            </li>
            } @else {
            <li class="leason">
                {{leasson.name}}
            </li>
            }

            }

        </ul>
    </div>
    }





</div>