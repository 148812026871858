import { Component , OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-methos',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './methos.component.html',
  styleUrl: './methos.component.scss'
})
export class MethosComponent implements OnInit {
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {

    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");
      }else{
        this.translate.setDefaultLang('fr');
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }
  }

}
