<div class="a">
    <div style="text-align: center;">
        <h1><b>404</b></h1>
        <h3>Oops... Page Not Found</h3>

        <h6>Sorry, the page you opened could not be found
            Make sure the URL you enter is correct</h6>

        <button class="bt" (click)="redirectToHomepage()">Homepage</button>
    </div>
</div>
