<div class="row">
    <div class="col-1"></div>
    <a class="col-8" href="dashboard-admin/cources" style="padding-bottom: 20px; text-decoration: none;">
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 7.99992H17.5M8.33333 3.33325L2.5 7.99992L8.33333 3.33325ZM2.5 7.99992L8.33333 12.6666L2.5 7.99992Z"
          stroke="#282938" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      {{ 'HOMEPAGE' | translate }}
    </a>
  </div>

<div style="display: flex; flex-direction: column;" class="container">
    <h1> {{name}} </h1>
    <button class="m-4" (click)="addNewQuestion()"   style="background-color: #FFC825;" mat-flat-button color="primary">{{ 'BUTTON_ADD_QUESTION' | translate }}</button>

    @if(showAddform){

    <mat-form-field>
        <mat-label>{{ 'LABEL_NEW_QUESTION' | translate }}</mat-label>
        <input [(ngModel)]="newQuestion" matInput>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ 'LABEL_NEW_NOTES' | translate }}</mat-label>
        <input [(ngModel)]="newNotes" matInput>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ 'LABEL_NEW_POINTS' | translate }}</mat-label>
        <input type="number" [(ngModel)]="newPoints" matInput>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ 'LABEL_QUESTION_CATEGORY' | translate }}</mat-label>
        <mat-select [(ngModel)]="newQuestionType">
            @for(category of questionTypes; track category){
            <mat-option [value]="category">{{category | translate}}</mat-option>
            }
        </mat-select>
    </mat-form-field>

    @if( newQuestionType !='multiple answers' ){
        <mat-form-field>
            <mat-label> {{ 'LABEL_ANSWER' | translate }}</mat-label>
            <input [(ngModel)]="newAnswer" matInput>
        </mat-form-field>
    }

    @if( newQuestionType =='single answer' || newQuestionType =='multiple answers' ){
        <div>
            <mat-form-field>
                <mat-label>{{ 'LABEL_ADD_NEW_OPTIONS' | translate }}</mat-label>
                <input [(ngModel)]="newOptionsInput" matInput>
            </mat-form-field>
            <button (click)="addnewOptions()" mat-fab color="primary" aria-label="Example icon button with a plus icon">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <mat-chip-set aria-label="Fish selection">
            @for (user of newOptions; track user) {
            <mat-chip>{{user}}</mat-chip>

            } @empty {
            <mat-chip disabled>{{ 'LABEL_NO_NEW_OPTIONS' | translate }}</mat-chip>
            }
        </mat-chip-set>

    }


    @if( newQuestionType =='multiple answers' ){
        <div>
            <mat-form-field>
                <mat-label>{{ 'LABEL_ADD_NEW_ANSWERS' | translate }}</mat-label>
                <input [(ngModel)]="newAnswersInput" matInput>
            </mat-form-field>
            <button (click)="addnewAnswers()" mat-fab color="primary" aria-label="Example icon button with a plus icon">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <mat-chip-set aria-label="Fish selection">
            @for (user of newAnswers; track user) {
            <mat-chip>{{user}}</mat-chip>

            } @empty {
            <mat-chip disabled>no new Answers</mat-chip>
            }
        </mat-chip-set>
    }


    @if(addState){
        <button (click)="createNewQuestion()"class=" col-12 btn btn-primary" style="background-color: #FFC825;" mat-flat-button color="accent">{{ 'BUTTON_SUBMIT' | translate }}</button>
    } @else{
        <button (click)="saveQuestion()" class="m-4" mat-flat-button color="accent" style="background-color: #FFC825;" >{{ 'BUTTON_UPDATE' | translate }}</button>
    }



    }
</div>

@for(question of questions ; track question){
<div class="m-4">
    <table class="table container">
        <thead>
            <tr class="row">
                <th class="col-md-1">#</th>
                <th class="col-md-5">{{ 'LABEL_QUESTION' | translate }}</th>
                <th class="col-md-3">{{ 'LABEL_TYPE' | translate }}</th>
                <th class="col-md-3">{{ 'LABEL_ACTIONS' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr class="row">
                <td class="col-md-1" > {{question.id}} </td>
                <td class="col-md-5"> {{question.question}} </td>
                <td class="col-md-3"> {{question.questionType}} </td>
                <td class="col-md-3">
                    <button (click)="openDialog(question.id)" class="mx-4" mat-mini-fab color="primary" style="background-color: #FFC825;"
                        aria-label="Example icon button with a delete icon">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button (click)="select2edit($index)" class="mx-4" mat-mini-fab color="primary" style="background-color: #FFC825;" aria-label="Example icon button with a edit icon">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

} @empty {

    <div class="container p-md-4">
        <div class=" skeleton ">
            <div class="skeleton-left flex1">
                <div class="square"></div>
            </div>
            <div class="skeleton-right flex2">
                <div class="line h17 w40 m10"></div>
                <div class="line"></div>
                <div class="line h8 w50"></div>
                <div class="line  w75"></div>
            </div>
        </div>
    </div>
}