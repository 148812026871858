import { Component, OnInit } from '@angular/core';
import { NavComponent } from '../../compoments/commun/nav/nav.component';
import { PathComponent } from '../../compoments/commun/path/path.component';
import { FooterComponent } from '../../compoments/commun/footer/footer.component';
import { DescriptionComponent } from '../../compoments/DetailCourse/description/description.component';
import { ContentComponent } from '../../compoments/DetailCourse/content/content.component';
import { JoinComponent } from '../../compoments/DetailCourse/join/join.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-course',
  standalone: true,
  imports: [TranslateModule,NavComponent, PathComponent, FooterComponent, DescriptionComponent, ContentComponent, JoinComponent, HttpClientModule],
  templateUrl: './detail-course.component.html',
  styleUrl: './detail-course.component.scss'
})
export class DetailCourseComponent implements OnInit {

  constructor(private translate: TranslateService,private http: HttpClient, private route: ActivatedRoute, private router: Router) {}
  name: string = ""
  description: string = ""
  category: string = ""
  videoUrl: string = ""
  picture: string = ""
  briefing:string=""
  id:string=""
  price:string=""

  passingScore:number=0

  instructor:string=""
  syllabus:string=""
  precondition:string=""
  teachingMethod:string=""
  testimonials:string=""

   faqList:any[]=[]

   formationIdentifier = ""
   somedate = ""
   somedate2 = ""
   apprentissage = ""
   mode = ""

  ngOnInit(): void {

    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");

      }

    } catch (error) {
      this.translate.setDefaultLang('fr');

    }
    const apiUrl = ` https://soacwaaslearning.studiolab.fr/elearning/course/getCource/${this.route.snapshot.paramMap.get('id')}`
    this.http.get(apiUrl).subscribe((result: any) => {
      this.name = result.name;
      this.passingScore=result.passingScore
      this.category = result.category;
      this.videoUrl = result.videoUrl;
      this.briefing = result.briefing;
      this.picture = result.picture;
      this.price = result.price;
      this.id = result.id;

      this.description = result.description;

      this.instructor = result.instructor;
      this.syllabus = result.syllabus;
      this.precondition = result.precondition;
      this.teachingMethod = result.teachingMethod;
      this.testimonials = result.testimonials;

      this.faqList=result.faqList
      this.formationIdentifier=result.formationIdentifier
      this.somedate=result.somedate
      this.somedate2=result.somedate2
      this.apprentissage=result.apprentissage
      this.mode=result.mode

    })
  }

  changeLanguage=(lang:string)=>{
    this.translate.setDefaultLang(lang)
  }

}
