import { Component, ViewChild, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { SidebarLeassonComponent } from '../../compoments/leason/sidebar-leasson/sidebar-leasson.component';
import { NavbarLeassonComponent } from '../../compoments/leason/navbar-leasson/navbar-leasson.component';
import { LeasonBodyComponent } from '../../compoments/leason/leason-body/leason-body.component';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageServiceService } from '../../storage.service.service';
@Component({
  selector: 'app-leassons',
  standalone: true,
  imports: [MatSidenavModule, MatButtonModule, SidebarLeassonComponent, NavbarLeassonComponent, LeasonBodyComponent, HttpClientModule],
  templateUrl: './leassons.component.html',
  styleUrl: './leassons.component.scss'
})
export class LeassonsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private storageService: StorageServiceService) { }

  currentLeasson = ""
  currentLeassonIndex = 0

  firstLeasson = true
  lastLeasson = false

  openLeasson = false

  showFiller = false;

  infos: any

  showleasson = false



  leassons: any
  @ViewChild('drawer') drawer!: MatDrawer;

  toggleDrawer() {
    this.drawer.toggle();
  }

  verifLeassonStatus(id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.storageService.getUser().tokenType} ${this.storageService.getUser().accessToken}`
    });
    const apiUrl = ` https://soacwaaslearning.studiolab.fr/elearning/course/setLeasonCourceByStudent/${this.infos.id}/${id}/${this.leassons.length}`
    this.http.put(apiUrl, {}, { headers }).subscribe((resulte: any) => {
      console.log("xxx   :::: ",resulte)
      try {
        if (this.infos.passed ) {
          this.router.navigate([  `/attestation/${this.route.snapshot.paramMap.get('idc')}` ]);

        }else{
          try {
            if (this.infos.progress == 100 ) {
              this.router.navigate([`/cources/exam/${this.route.snapshot.paramMap.get('idc')}`]);

            }
          } catch (error) {

          }
          try {
            if (resulte.progress == 100) {
              this.router.navigate([`/cources/exam/${this.route.snapshot.paramMap.get('idc')}`]);

            }else{
              this.infos=resulte
            }
          } catch (error) {

          }
        }
      } catch (error) {
      }



    })
  }

  finishButton = () => {
    this.verifLeassonStatus(this.leassons[this.currentLeassonIndex].id)
    // this.router.navigate([`/cources/exam/${this.route.snapshot.paramMap.get('idc')}`  ])
  }

  nextPage = () => {
    this.verifLeassonStatus(this.leassons[this.currentLeassonIndex].id)


    this.currentLeasson = this.leassons[this.currentLeassonIndex + 1].id
    this.currentLeassonIndex = this.currentLeassonIndex + 1
    this.firstLeasson = false
    if (this.currentLeassonIndex == (this.leassons.length - 1)) {
      this.lastLeasson = true
    }

  }

  previousPage = () => {
    this.currentLeasson = this.leassons[this.currentLeassonIndex - 1].id
    this.currentLeassonIndex = this.currentLeassonIndex - 1
    this.lastLeasson = false
    if (this.currentLeassonIndex == 0) {
      this.firstLeasson = true
    }
  }

  ngOnInit(): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.storageService.getUser().tokenType} ${this.storageService.getUser().accessToken}`
    });
    const apiUrl = ` https://soacwaaslearning.studiolab.fr/elearning/course/getCourceByStudent/${this.route.snapshot.paramMap.get('idc')}`
    this.http.get(apiUrl, { headers }).subscribe((resulte: any) => {
      this.infos = resulte
      console.log("resulte  ::::    ", resulte)
      const apiUrl1 = ` https://soacwaaslearning.studiolab.fr/elearning/leasson/getLeassonsByCourse/${this.route.snapshot.paramMap.get('idc')}`
      this.http.get(apiUrl1).subscribe((result: any) => {
        console.log("cource list", result);
        this.leassons = result
        this.showleasson = true

        try {
          if (resulte.leassonsIds.length == 0) {
            this.firstLeasson = true
          } else {
            this.firstLeasson = false
          }
          if (resulte.leassonsIds.length >= (this.leassons.length-1)) {
            this.lastLeasson = true
          } else {
            this.lastLeasson = false
          }
          this.currentLeassonIndex = resulte.leassonsIds.length
          this.currentLeasson = this.leassons[resulte.leassonsIds.length].id
        } catch (error) {
          this.lastLeasson = true
          this.currentLeassonIndex = resulte.leassonsIds.length - 1
          this.currentLeasson = this.leassons[resulte.leassonsIds.length - 1].id
        }
      })
    })

  }
}
