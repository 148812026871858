import { Component, HostListener, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../Services/auth.service';
import { StorageServiceService } from '../../../storage.service.service';
import { Subscription } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, MatIconModule, CommonModule, TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss'
})
export class NavComponent implements OnInit {

  public roles: string[] = [];
  isLoggedIn !: boolean;
  showAdminBoard = false;
  showDevBoard = false;
  username?: string;

  eventBusSub?: Subscription;
  session!: any;
  profileImageUrl: string | undefined;
  constructor(
    private translate: TranslateService,
    private storageService: StorageServiceService,
    private authService: AuthService,
    private router: Router) {

  }

  ngOnInit(): void {
    try {
      if (window.location.pathname !== "/") {



        setTimeout(() => {
          let navbar = document.getElementById("navbar") as HTMLElement
          navbar.style.backgroundColor = "white"
          let links = navbar.getElementsByTagName("a");
          for (let i = 0; i < links.length; i++) {
            links[i].style.color = "#339933"; // Set the color of hyperlinks to #339933
          }
          try {
            let avatarname = document.getElementsByClassName("avatar-name")[0] as HTMLElement
            let avatarjob = document.getElementsByClassName("avatar-job")[0] as HTMLElement
            avatarname.style.color = "#339933"
            avatarjob.style.color = "#339933"
          } catch (error) {

          }


          let conexionButton = document.getElementById("conexionButton")as HTMLElement;
          console.log(conexionButton);

          conexionButton.classList.remove("btn-outline-light");
          conexionButton.classList.add("btn");
          conexionButton.classList.add("btn-success");
          conexionButton.style.color="white"
        }, 500);



      }
    } catch (error) {

    }


    this.translate.addLangs(['en', 'fr']);

    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      } else {
        this.translate.setDefaultLang('fr');
      }

    } catch (error) {


    }



    this.isLoggedIn = this.storageService.isLoggedIn();

    if (this.isLoggedIn) {
      const user = this.storageService.getUser();
      this.roles = user.roles;

      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showDevBoard = this.roles.includes('ROLE_DEVELOPER');

      this.username = user.username;

      // Obtenir l'image de profil de l'utilisateur connecté
      if (this.username) {
        this.authService.getProfileImage(this.username).subscribe({
          next: (response) => {
            // Créez une URL d'objet pour le Blob et stockez-la dans profileImageUrl

            this.profileImageUrl = URL.createObjectURL(response);
          },
          error: (err) => {
            console.error('Erreur lors de la récupération de l\'image de profil:', err);
          }
        });
      }
    }


  }

  changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
    localStorage.setItem("lang", lang)
  }

  getFlag(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "assets/en.png"
      } else {
        return "assets/fr.png"
      }
    } catch (e) {
      return "assets/fr.png"

    }
  }
  getDiffFlag(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "assets/fr.png"
      } else {
        return "assets/en.png"
      }
    } catch (e) {
      return "assets/en.png"

    }
  }
  getDiffLang(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "Francais"
      } else {
        return "English"
      }
    } catch (e) {
      return "English"

    }
  }
  getDiffLangAp(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "fr"
      } else {
        return "en"
      }
    } catch (e) {
      return "en"

    }
  }

  isLoged: boolean = true
  logout(): void {
    this.authService.logout().subscribe({
      next: res => {
        this.storageService.clean();
        localStorage.clear();
        this.router.navigateByUrl('/login');
      },
      error: err => {
        console.log(err);
      }
    });
  }
  profile(): void {
    this.router.navigateByUrl('/profile');
  }

  goToHome() {
    window.location.href=""
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const navbar = document.getElementById('navbar');
    try {
      if (window.scrollY > 100) {
        navbar?.classList.add('scrolled');
      } else {
        navbar?.classList.remove('scrolled');
      }
    } catch (error) {

    }

  }

  showNavBar: boolean = true


  toggleNavBar() {
    this.showNavBar = !this.showNavBar
  }


  navigateTolOGIN() {

    this.router.navigate(['/login']);

  }

  navigateToAbout() {
    if (this.roles.includes('ROLE_STUDENT')) {
      this.router.navigate(['/dashboard-student']);

    } else {
      this.router.navigate(['/dashboard-admin']);

    }
  }

  navigateToProfile() {
    this.router.navigate(['/profile']);
  }

}
