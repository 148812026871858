<nav id="navbar">


    <h1>
        <img (click)="goToHome()" class="officialLogo" width="200" height="80" src="assets/SEMSOAC E LEARNING.png">
        <button class="btn btn-outline-light d-block d-sm-none" (click)="toggleNavBar()" style="float: right;">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-justify"
                viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
            </svg>
        </button>
    </h1>


    <div class="d-none d-sm-block">
        <a href="#id-header">
            <b>  {{'HOME' | translate}}</b>

        </a>

        <a style="width: auto;" href="#id-obout">
            <b>{{'ABOUT US' | translate}}</b>
        </a>
        <a href="/cources">
          <b>  {{'COURSES' | translate}}</b>
        </a>
        <a href="https://www.soacwaas.org/actualites.html">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.33334 2.99992H3.00001C2.55798 2.99992 2.13406 3.17551 1.8215 3.48807C1.50894 3.80063 1.33334 4.22456 1.33334 4.66658V12.9999C1.33334 13.4419 1.50894 13.8659 1.8215 14.1784C2.13406 14.491 2.55798 14.6666 3.00001 14.6666H11.3333C11.7754 14.6666 12.1993 14.491 12.5119 14.1784C12.8244 13.8659 13 13.4419 13 12.9999V9.66658M9.66668 1.33325H14.6667M14.6667 1.33325V6.33325M14.6667 1.33325L6.33334 9.66658"
                    stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </a>

        @if(!isLoggedIn){
            <a style="margin-right: 50px;">
                <button id="conexionButton" (click)="navigateTolOGIN()" class="btn btn-outline-light ">{{'LOGIN_BUTTON' | translate}} </button>
            </a>
        }

        @if(isLoggedIn){
        <span style="margin-right: 50px;" class="d-flex">
            <div class="d-flex">
                @if(profileImageUrl){
                <img class="avatar" [src]="profileImageUrl" alt="{{'AVATAR' | translate}}">
                }
                <div>
                    <p class="avatar-name">{{ username }}</p>
                    <p class="avatar-job">{{ roles }}</p>
                </div>
                <button mat-icon-button [matMenuTriggerFor]="menu" style="color: #32ab3cc3;">
                    <mat-icon>expand_more</mat-icon>
                </button>
            </div>

            <mat-menu #menu="matMenu">
                <button (click)="navigateToAbout()" mat-menu-item>
                    <mat-icon>dialpad</mat-icon>
                    <span>{{'DASHBOARD' | translate}}</span>
                </button>
                <button (click)="navigateToProfile()" mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>{{'PROFILE' | translate}}</span>
                </button>
                <button mat-menu-item (click)="logout()">
                    <mat-icon>logout</mat-icon>
                    <span>{{'LOGOUT' | translate}}</span>

                </button>
            </mat-menu>
        </span>
        }
        <a>
            <span class="d-flex">
                <div class="d-flex px-md-5">

                    <button mat-icon-button [matMenuTriggerFor]="menulang">
                        <img style="width: 30px; height: 30px;" [src]="getFlag()" alt="{{'AVATAR' | translate}}">
                    </button>
                    <button mat-icon-button [matMenuTriggerFor]="menulang" style="color: #32ab3cc3;">
                        <mat-icon>expand_more</mat-icon>
                    </button>
                </div>

                <mat-menu #menulang="matMenu">
                    <button mat-menu-item (click)="changeLanguage(getDiffLangAp())">
                        <span> <img style="width: 35px; height: 35px;" [src]="getDiffFlag()"> {{getDiffLang()}}</span>
                    </button>
                </mat-menu>
            </span>
        </a>

    </div>

    @if(!showNavBar){
    <div>
        <a class="m-4 d-block" href="#id-header">
            {{'HOME' | translate}}
        </a>
        <a class="m-4 d-block" href="#id-obout">
            {{'ABOUT US' | translate}}
        </a>
        <a class="m-4 d-block" href="/cources">
            {{'COURSES' | translate}}
        </a>
        <a href="https://www.soacwaas.org/actualites.html" class="m-4 d-block">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.33334 2.99992H3.00001C2.55798 2.99992 2.13406 3.17551 1.8215 3.48807C1.50894 3.80063 1.33334 4.22456 1.33334 4.66658V12.9999C1.33334 13.4419 1.50894 13.8659 1.8215 14.1784C2.13406 14.491 2.55798 14.6666 3.00001 14.6666H11.3333C11.7754 14.6666 12.1993 14.491 12.5119 14.1784C12.8244 13.8659 13 13.4419 13 12.9999V9.66658M9.66668 1.33325H14.6667M14.6667 1.33325V6.33325M14.6667 1.33325L6.33334 9.66658"
                    stroke="green" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </a>

        @if(!isLoggedIn){
        <button (click)="navigateTolOGIN()" type="button" class="btn btn-outline-light m-4">{{'LOGIN_BUTTON' |
            translate}}</button>
        }
        @if(isLoggedIn){
        <span class="d-flex">
            <div class="d-flex">
                @if(profileImageUrl){
                <img class="avatar" [src]="profileImageUrl" alt="{{'AVATAR' | translate}}">
                }
                <div>
                    <p class="avatar-name">{{ username }}</p>
                    <p class="avatar-job">{{ roles }}</p>
                </div>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>expand_more</mat-icon>
                </button>
            </div>
            <a>
                <span class="d-flex">
                    <div class="d-flex px-md-5">

                        <button mat-icon-button [matMenuTriggerFor]="menulang">
                            <img style="width: 30px; height: 30px;" [src]="getFlag()" alt="{{'AVATAR' | translate}}">
                        </button>
                        <button mat-icon-button [matMenuTriggerFor]="menulang">
                            <mat-icon>expand_more</mat-icon>
                        </button>
                    </div>

                    <mat-menu #menulang="matMenu">
                        <button mat-menu-item (click)="changeLanguage(getDiffLangAp())">
                            <span> <img style="width: 35px; height: 35px;" [src]="getDiffFlag()">
                                {{getDiffLang()}}</span>
                        </button>
                    </mat-menu>
                </span>
            </a>

            <mat-menu #menu="matMenu">
                <button (click)="navigateToAbout()" mat-menu-item>
                    <mat-icon>dialpad</mat-icon>
                    <span>{{'DASHBOARD' | translate}}</span>
                </button>
                <button (click)="navigateToProfile()" mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>{{'PROFILE' | translate}}</span>
                </button>
                <button mat-menu-item (click)="logout()">
                    <mat-icon>logout</mat-icon>
                    <span>{{'LOGOUT' | translate}}</span>

                </button>
            </mat-menu>
        </span>
        }

    </div>
    }
</nav>