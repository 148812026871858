<mat-drawer-container  style="width: 100%; height: 100%;" autosize>
    <mat-drawer #drawer mode="side">
        <app-sidebar-leasson  [leassons]="leassons"  [infos]="infos" ></app-sidebar-leasson>
    </mat-drawer>

    <div >
        <app-navbar-leasson (sidenav)="drawer.toggle()"></app-navbar-leasson>

        <app-leason-body [finishButton]="finishButton" [previousPage]="previousPage" [nextPage]="nextPage" [firstLeasson]="firstLeasson" [lastLeasson]="lastLeasson" [currentLeasson]="currentLeasson" [showleasson]="showleasson"  ></app-leason-body>
    </div>

</mat-drawer-container>