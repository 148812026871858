<div>
    <label class="question" for="exampleFormControlTextarea1">{{questionNumber+1}}. {{qt.question.question}}
        @if(qt.question.notes){
            <span class="notes"> {{ "("+  qt.question.notes + ")"}}</span>
        }
    </label>

    @for( ans of qt.question.options ; track ans ){
        <div class="form-check">
            <input (click)="checkClick(ans,$event)" class="form-check-input" type="checkbox" [name]="questionNumber+1+'option'+$index" [id]="questionNumber+1+'option'+$index" [value]="ans" >
            <label class="form-check-label" [for]="questionNumber+1+'option'+$index">
                {{ans}}
            </label>
        </div>
    }


</div>