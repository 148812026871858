import { Component } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-exam-description',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './exam-description.component.html',
  styleUrl: './exam-description.component.scss'
})
export class ExamDescriptionComponent {

  constructor(
    private translate: TranslateService,

   ) {

  }


  ngOnInit(): void {
    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");

      }

    } catch (error) {
      this.translate.setDefaultLang('fr');

    }}

    changeLanguage = (lang: string) => {
      this.translate.setDefaultLang(lang)
      localStorage.setItem("lang", lang)
    }

}
