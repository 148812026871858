import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavbarLeassonComponent } from '../../compoments/leason/navbar-leasson/navbar-leasson.component';
import { SidebarStudentComponent } from '../../compoments/dashboard-student/sidebar-student/sidebar-student.component';
import { StudentsChartsComponent } from '../../compoments/dashboard-student/students-charts/students-charts.component';
import { CoursesProgressComponent } from '../../compoments/dashboard-student/courses-progress/courses-progress.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-dashboard-student',
  standalone: true,
  imports: [MatSidenavModule,RouterModule, MatButtonModule,NavbarLeassonComponent,SidebarStudentComponent,StudentsChartsComponent,CoursesProgressComponent],
  templateUrl: './dashboard-student.component.html',
  styleUrl: './dashboard-student.component.scss'
})
export class DashboardStudentComponent {

}
