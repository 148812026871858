import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { StorageServiceService } from '../../../storage.service.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-join',
  standalone: true,
  imports: [
    TranslateModule, CommonModule,
    MatButtonModule, MatMenuModule, MatIconModule
  ],
  templateUrl: './join.component.html',
  styleUrl: './join.component.scss'
})
export class JoinComponent implements OnInit {
  constructor(private translate: TranslateService, private _snackBar: MatSnackBar, private http: HttpClient, private router: Router, private storageService: StorageServiceService) {

  }
  @Input() id: string = ""
  @Input() price: string = ""
  @Input() name: string = ""
  @Input() formationIdentifier: string = ""
  @Input() somedate: string = ""
  @Input() somedate2: string = ""
  @Input() apprentissage: string = ""
  @Input() mode: string = ""

  @Input() passingScore: number = 0
  displayContent=true

  toggleDisplay(){
    this.displayContent=!this.displayContent
  }

  isLoggedIn !: boolean;


  cources: any[] = []
  navigateToAbout() {
    this.router.navigate([`/learning/course/${this.id}`]);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "", {
      duration: 3000
    });
  }

  subscribeCource() {

    if (this.cources.find(el => { return el.idCource == this.id })) {
      this.router.navigate([`/learning/course/${this.id}`]);
    } else {
      this.router.navigate([`/cources/pay/${this.id}`]);

      // const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/subcribe"
      // const headers = new HttpHeaders({
      //   'Content-Type': 'application/json',
      //   'Authorization': `${this.storageService.getUser().tokenType} ${this.storageService.getUser().accessToken}`
      // });

      // let data1 = {
      //   "idCource": this.id,
      //   "courceName": this.name,
      //   "leassonsIds": [],
      //   "progress": "0",
      //   "passingScore": this.passingScore
      // }

      // this.http.post(apiUrl, data1, { headers }).subscribe((result: any) => {

      //   this.openSnackBar(result.message)

      //   setTimeout(() => {
      //     this.navigateToAbout()
      //   }, 2000);
      // })
    }


  }

  ngOnInit(): void {
    this.isLoggedIn = this.storageService.isLoggedIn();

    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");

      }

    } catch (error) {
      this.translate.setDefaultLang('fr');

    }
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/getCourceFromStudent"
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.storageService.getUser().tokenType} ${this.storageService.getUser().accessToken}`
    });

    this.http.get(apiUrl, { headers }).subscribe((result: any) => {

      console.log("result :: ", result);
      this.cources = result

    })

  }

  changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
  }


}
