<div class="sectiontitle">
  <h2>{{ 'projectsStatistics' | translate }}</h2>
  <span class="headerLine"></span>
</div>
<div id="projectFacts" class="sectionClass">
  <div class="projectFactsWrap">

    @if(!isAssistance){
      <div class="item" data-number="55">
        <i class="fa fa-user"></i>
        <p id="number2" class="number">{{ totalAssistants }}</p>
        <p class="spaced">{{ 'allAssistants' | translate }}</p>
      </div>
    }

    <div class="item" data-number="359">
      <i class="fa fa-user"></i>
      <p id="number3" class="number">{{ totalStudents }}</p>
      <p class="spaced">{{ 'allStudents' | translate }}</p>
    </div>

    <div class="item" data-number="246">
      <i class="fa fa-book"></i>
      <p id="number4" class="number">{{ totalCours }}</p>
      <p class="spaced">{{ 'allCourses' | translate }}</p>
    </div>

    <div class="item" data-number="12">
      <i class="fa fa-book"></i>
      <p id="number1" class="number">{{ totalCoursAttended }}</p>
      <p class="spaced">{{ 'coursesAttended' | translate }}</p>
    </div>

  </div>
</div>
