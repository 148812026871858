import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-question-type4',
  standalone: true,
  imports: [],
  templateUrl: './question-type4.component.html',
  styleUrl: './question-type4.component.scss'
})
export class QuestionType4Component {
  @Input() qt: any;
  @Input() questionNumber: number=0;

  checkClick(s:string , e:any){
    if(this.qt.answer.indexOf(s)==-1){
      this.qt.answer.push(s)

    }else{
      this.qt.answer=this.qt.answer.filter((el:string)=>{ return el!=s})

    }
   }
}
