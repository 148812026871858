import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-question-type3',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './question-type3.component.html',
  styleUrl: './question-type3.component.scss'
})
export class QuestionType3Component {
  @Input() qt: any;
  @Input() questionNumber: number=0;

 checkClick(s:string , e:any){
  if(this.qt.answer==s){
    this.qt.answer=""
    e.target.checked=false
  }else{
    this.qt.answer=s
  }
 }
}
