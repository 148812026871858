<mat-accordion>
    @for(faq of faqList ; track faq.question){
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ faq.question }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ faq.answer }}</p>
        </mat-expansion-panel>
    }

</mat-accordion>