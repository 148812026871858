import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpClientModule, HttpEventType, HttpHeaders } from '@angular/common/http';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-update-leasson',
  standalone: true,
  imports: [PdfViewerModule,MatProgressSpinnerModule,TranslateModule,MatProgressBarModule, HttpClientModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule],
  templateUrl: './update-leasson.component.html',
  styleUrl: './update-leasson.component.scss'
})
export class UpdateLeassonComponent {
  isloading=false

  ngOnInit(): void {
    const apiUrl = `https://soacwaaslearning.studiolab.fr/elearning/course/getAll`
    const apiUrl1 = `https://soacwaaslearning.studiolab.fr/elearning/leasson/getLeasson/${this.route.snapshot.paramMap.get('id')}`
    this.http.get(apiUrl).subscribe((result: any) => {
      this.cources = result
    })

    this.http.get(apiUrl1).subscribe((result: any) => {
      this.id = result.id
      this.videoUrl = result.videoUrl
      this.pdfUrl = result.pdfUrl
      this.videoDuretion = result.videoDuretion
      this.indexOfLeasson = result.indexOfLeasson
      this.chapter = result.chapter
      this.title = result.title
      this.cource = result.courceId
    })
  }
  id = ""
  selectedFile: File | null = null;
  progress: number = 0;
  videoUrl: string = '';
  videoDuretion: number = 0;
  indexOfLeasson: number = 0;
  chapter: string = '';
  title: string = '';
  cource: string = '';
  pdfUrl: string = '';


  cources = [
    {
      "id": 1,
      "name": ""
    },
    {
      "id": 2,
      "name": "dzdzdz"
    },
    {
      "id": 3,
      "name": "defzef"
    },
    {
      "id": 4,
      "name": "frgerge"
    }
  ]

  constructor(private translate: TranslateService,private _snackBar: MatSnackBar,private router: Router,private http: HttpClient, private route: ActivatedRoute) {
    translate.addLangs(['en', 'fr']);

    translate.setDefaultLang('en');
  }
  openSnackBar(message: string) {
    this._snackBar.open(message,"",{
      duration: 3000
    });
  }
  onFileSelected(event: Event): void { // Explicitly type the event parameter
    const input = event.target as HTMLInputElement;
    if (input && input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      const videoURL = URL.createObjectURL(this.selectedFile);

      // Create a video element
      const videoElement = document.createElement('video');
      videoElement.src = videoURL;

      // Listen for the loadedmetadata event to get the video duration
      videoElement.addEventListener('loadedmetadata', () => {
        const duration = videoElement.duration;
        this.videoDuretion = duration

        // Clean up the object URL
        URL.revokeObjectURL(videoURL);
      });

    } else {
      this.selectedFile = null;
    }
  }

  changeLanguage=(lang:string)=>{
    this.translate.setDefaultLang(lang)
  }

  onUpload(): void {
    this.videoUrl=""

    if (this.selectedFile) { // Type guard to check if selectedFile is not null
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);
      this.isloading=true
      this.http.post('https://soacwaaslearning.studiolab.fr/elearning/api/videos/upload', formData, {
        reportProgress: true,
        observe: 'events'
      })
        .subscribe((event: any) => {

          if (event.type === HttpEventType.UploadProgress && event.total) {
            this.progress = Math.round((event.loaded / event.total) * 100);
          } else if (event.type === HttpEventType.Response) {
            this.isloading=false

            this.videoUrl = `https://soacwaaslearning.studiolab.fr/elearning/api/videos/stream/${event["body"]["name"]}`;
          }
        }, error => {
          console.error('Error in POST request:', error);
        });
    } else {
      console.error('No file selected');
    }
  }

  uploadPDF(e:any){
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    this.http.post('https://soacwaaslearning.studiolab.fr/elearning/api/images/upload', formData, {
        reportProgress: true,
        observe: 'events'
      })
        .subscribe((event: any) => {
            this.pdfUrl = `https://soacwaaslearning.studiolab.fr/elearning/api/images/view/${event.body.message}`;
        });
  }
zoom=1
createLeason() {
  const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/leasson/update";
  const headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  let data1 = {
    id: this.id,
    videoUrl: this.videoUrl,
    pdfUrl: this.pdfUrl,
    videoDuretion: this.videoDuretion,
    chapter: this.chapter,
    title: this.title,
    courceId: this.cource,
    indexOfLeasson: this.indexOfLeasson
  };

  this.http.put(apiUrl, data1, { headers }).subscribe(result => {
    const successMessage = this.translate.instant('leasson_updated_successfully'); // Utilisation de la clé de traduction
    this.openSnackBar(successMessage);

    setTimeout(() => {
      this.router.navigate(['dashboard-admin/cource-update/' + this.cource]);
    }, 2000);
  });
}

}
