import { CommonModule } from '@angular/common';
import { Component, Output, EventEmitter, Input ,OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-leasson',
  standalone: true,
  imports: [MatSidenavModule,CommonModule],
  templateUrl: './sidebar-leasson.component.html',
  styleUrl: './sidebar-leasson.component.scss'
})
export class SidebarLeassonComponent implements OnChanges {

  @Input() leassons:any
  @Input() infos:any

  videosCount=20
  videosCountWatched=4


  @Output() toggleSidenav = new EventEmitter<void>();
  showFiller = false;

  chapters=[
    {
      chapterName:"Chapter 1",
      leassons:[
        {
          id:"1",
          active:true,
          name:"leasson 1"
        },
        {
          id:"2",
          active:false,
          name:"leason1"
        }
      ]
    },

    {
      chapterName:"Chapter x",
      leassons:[
        {
          id:"1",
          active:true,
          name:"leasson 1"
        },
        {
          id:"2",
          active:false,
          name:"leason1"
        }
      ]
    }
  ]
  goToHome() {
    this.router.navigateByUrl('/');
  }
  constructor(private router: Router){}
  ngOnChanges(changes: SimpleChanges) {
    if (changes['infos'].currentValue) {
      this.chapters=[]
      setTimeout(() => {
        this.videosCount= this.leassons.length
        this.videosCountWatched=this.infos.leassonsIds.length
        this.leassons.forEach((element:any,index:number)=>{

          let t= this.chapters.findIndex((el)=>{

            return el.chapterName==element.chapter
          })

          if(t==-1){

            this.chapters.push(
              {
                chapterName:element.chapter,
                leassons:[
                  {
                    id:element.id,
                    active:(this.infos.leassonsIds.includes(element.id) || this.infos.leassonsIds.length==index),
                    name:element.title
                  }
                ]
              }
            )
          }else{
            console.log(element);

            this.chapters[t].leassons.push(
              {
                id:element.id,
                active:(this.infos.leassonsIds.includes(element.id) || this.infos.leassonsIds.length==index),
                name:element.title
              }
            )
          }
        })
      }, 2000);



    }


  }

  // ngOnInit(): void {
  //   this.chapters=[]
  //   setTimeout(() => {
  //     this.videosCount= this.leassons.length
  //     this.videosCountWatched=this.infos.leassonsIds.length
  //     this.leassons.forEach((element:any,index:number)=>{

  //       let t= this.chapters.findIndex((el)=>{return el.chapterName==element.chapter})

  //       if(t==-1){
  //         this.chapters.push(
  //           {
  //             chapterName:element.chapter,
  //             leassons:[
  //               {
  //                 id:element.id,
  //                 active:(this.infos.leassonsIds.includes(element.id) || this.infos.leassonsIds.length==index),
  //                 name:element.title
  //               }
  //             ]
  //           }
  //         )
  //       }else{
  //         this.chapters[t].leassons.push(
  //           {
  //             id:element.id,
  //             active:true,
  //             name:element.title
  //           }
  //         )
  //       }
  //     })
  //   }, 2000);




  // }

}
