<div class="side-bar-leason">
    <div class="logo">
        <img (click)="goToHome()" class="officialLogo" width="200" height="80" src="assets/SEMSOAC E LEARNING.png">
    </div>

    <div [ngClass]="{ 'active': getActiveClass('/dashboard-admin') }" (click)="navigateToAbout3()"
        class="top-sidebar-component">

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trello"
            viewBox="0 0 16 16">
            <path
                d="M14.1 0H1.903C.852 0 .002.85 0 1.9v12.19A1.9 1.9 0 0 0 1.902 16h12.199A1.9 1.9 0 0 0 16 14.09V1.9A1.9 1.9 0 0 0 14.1 0M7 11.367a.636.636 0 0 1-.64.633H3.593a.633.633 0 0 1-.63-.633V3.583c0-.348.281-.631.63-.633h2.765c.35.002.632.284.633.633zm6.052-3.5a.633.633 0 0 1-.64.633h-2.78A.636.636 0 0 1 9 7.867V3.583a.636.636 0 0 1 .633-.633h2.778c.35.002.631.285.631.633z" />
        </svg>

        <span>{{'statistics' | translate}}</span>
    </div>


    @if(!isAssistance){
    <div [ngClass]="{ 'active': getActiveClass('/dashboard-admin/assistant') }" (click)="navigateToAbout1()"
        class="top-sidebar-component ">

        <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_930)">
                <path
                    d="M15.8888 6.11106V15.8888M10.9999 9.77772V15.8888M6.11106 13.4444V15.8888M3.66661 20.7777H18.3333C18.9816 20.7777 19.6033 20.5202 20.0618 20.0618C20.5202 19.6033 20.7777 18.9816 20.7777 18.3333V3.66661C20.7777 3.01831 20.5202 2.39655 20.0618 1.93813C19.6033 1.47971 18.9816 1.22217 18.3333 1.22217H3.66661C3.01831 1.22217 2.39655 1.47971 1.93813 1.93813C1.47971 2.39655 1.22217 3.01831 1.22217 3.66661V18.3333C1.22217 18.9816 1.47971 19.6033 1.93813 20.0618C2.39655 20.5202 3.01831 20.7777 3.66661 20.7777Z"
                    stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1_930">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>

        <span>{{'Assistant' | translate}}</span>
    </div>
    }





    <div [ngClass]="{ 'active': getActiveClass('/dashboard-admin/cources') }" (click)="navigateToAbout2()"
        class="top-sidebar-component">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.3001 1.22217L7.7001 4.88883M7.7001 20.7777L1.7084 17.4484C1.52571 17.347 1.37204 17.191 1.26461 16.998C1.15717 16.805 1.10021 16.5826 1.1001 16.3557V3.19972C1.1002 2.99147 1.14819 2.78669 1.23952 2.60483C1.33084 2.42297 1.46248 2.27006 1.62193 2.1606C1.78138 2.05114 1.96336 1.98876 2.1506 1.97939C2.33784 1.97002 2.52413 2.01397 2.6918 2.10706L7.7001 4.88883V20.7777ZM7.7001 20.7777L14.3001 17.1111L7.7001 20.7777ZM7.7001 20.7777V4.88883V20.7777ZM14.3001 17.1111L19.3084 19.8928C19.4761 19.9859 19.6623 20.0299 19.8496 20.0205C20.0368 20.0111 20.2188 19.9488 20.3783 19.8393C20.5377 19.7298 20.6694 19.5769 20.7607 19.3951C20.852 19.2132 20.9 19.0084 20.9001 18.8002V5.64417C20.9 5.41725 20.843 5.19484 20.7356 5.00186C20.6282 4.80887 20.4745 4.65293 20.2918 4.5515L14.3001 1.22217V17.1111ZM14.3001 17.1111V1.22217V17.1111Z"
                stroke="#9197B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <span> {{'My Courses' | translate}} </span>
    </div>

    <div [ngClass]="{ 'active': getActiveClass('/dashboard-admin/generalfaq') }" (click)="navigateToAbout4()"
        class="top-sidebar-component">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-patch-question-fill" viewBox="0 0 16 16">
            <path
                d="M5.933.87a2.89 2.89 0 0 1 4.134 0l.622.638.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01zM7.002 11a1 1 0 1 0 2 0 1 1 0 0 0-2 0m1.602-2.027c.04-.534.198-.815.846-1.26.674-.475 1.05-1.09 1.05-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.7 1.7 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745.336 0 .504-.24.554-.627" />
        </svg>
        <span> FAQ </span>
    </div>




</div>