import { Component } from '@angular/core';
import Swal from "sweetalert2";
import { AuthService } from "../../Services/auth.service";
import { StorageServiceService } from "../../storage.service.service";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { MyhttpserviceService } from "../../Services/myhttpservice.service";
import { UserInfo } from "node:os";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule,
    MatButtonModule, MatMenuModule, MatIconModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  componentToShow: string = 'welcome';
  form: any = {
    username: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  forgotPasswordEmail: string = '';

  saveInfos = false


  // Services injectés
  constructor(
    private authService: AuthService,
    private storageService: StorageServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private http: MyhttpserviceService,
    private translate: TranslateService

  ) {


  }
  changeSaveInfos() {
    localStorage.setItem("saveInfos", !this.saveInfos + "")
    this.saveInfos = !this.saveInfos



  }

  ngOnInit(): void {
    try {
      this.saveInfos = Boolean(localStorage.getItem("saveInfos"))
    } catch (error) {

    }
    // Vérifier les paramètres de la route
    this.route.queryParams.subscribe(params => {
      if (params['code'] !== undefined) {
        this.http.getToken(params['code']).subscribe(result => {
          if (result) {
            this.componentToShow = 'protected';
          } else {
            this.componentToShow = 'welcome';
          }
        });
      }
    });



    // Vérifier si l'utilisateur est connecté
    if (this.storageService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.roles = this.storageService.getUser().roles;
    }


    this.translate.addLangs(['en', 'fr']);

    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      } else {
        this.translate.setDefaultLang('fr');
      }

    } catch (error) {


    }

    // Surveille les changements d'authentification avec Google

  }

  // Méthode pour gérer la connexion avec Google


  // Gère le profil de l'utilisateur après la connexion avec Google
  handleGoogleLogin(userProfile: UserInfo<any>): void {
    // Par exemple, enregistrez les données de l'utilisateur et naviguez vers la page souhaitée
    this.storageService.saveUser(userProfile);
    this.isLoggedIn = true;
    this.router.navigateByUrl('/navbar/addsession');
  }


  // Soumission du formulaire de connexion
  onSubmit(): void {
    const { username, password } = this.form;
    this.authService.login(username, password).subscribe({
      next: data => {
        this.storageService.saveUser(data);
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.roles = this.storageService.getUser().roles;
  
        this.translate.get([
          'login_success_title',
          'login_success_message'
        ]).subscribe(translations => {
          Swal.fire({
            title: translations['login_success_title'],
            text: translations['login_success_message'],
            icon: 'success'
          });
        });
  
        if (['ROLE_ADMIN', 'ROLE_ASSISTANT'].includes(this.roles[0])) {
          this.router.navigateByUrl('/dashboard-admin');
        } else {
          this.router.navigateByUrl('/');
        }
      },
      error: err => {
        this.translate.get([
          'login_failure_title',
          'login_failure_message'
        ]).subscribe(translations => {
          Swal.fire({
            title: translations['login_failure_title'],
            text: translations['login_failure_message'],
            icon: 'error'
          });
          this.errorMessage = err.error.message;
          this.isLoginFailed = true;
        });
      }
    });
  }
  

  // Gérer la demande de réinitialisation de mot de passe
  onForgotPassword(): void {
    this.authService.forgotPassword(this.forgotPasswordEmail).subscribe({
      next: data => {

        this.translate.get([
          'E-mail envoyé !',
          'Vérifiez votre boîte de réception pour le lien de réinitialisation de mot de passe.'
        ]).subscribe(translations => {
          Swal.fire({
            title: translations['E-mail envoyé !'],
            text: translations['Vérifiez votre boîte de réception pour le lien de réinitialisation de mot de passe.'],
            icon: 'success'
          });
        });
      },
      error: err => {
        Swal.fire({
          title: 'Erreur !',
          text: err.error.message,
          icon: 'error'
        });
      }
    });
  }

  // Gérer l'ouverture de la modal pour mot de passe oublié
  openForgotPasswordModal(event: MouseEvent): void {
    event.preventDefault();
    this.router.navigate(['/forgot-password']);
  }

  // Recharger la page actuelle
  reloadPage(): void {
    window.location.reload();
  }


  changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
    localStorage.setItem("lang", lang)
  }

  getFlag(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "assets/en.png"
      } else {
        return "assets/fr.png"
      }
    } catch (e) {
      return "assets/fr.png"

    }
  }
  getDiffFlag(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "assets/fr.png"
      } else {
        return "assets/en.png"
      }
    } catch (e) {
      return "assets/en.png"

    }
  }
  getDiffLang(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "Francais"
      } else {
        return "English"
      }
    } catch (e) {
      return "English"

    }
  }
  getDiffLangAp(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "fr"
      } else {
        return "en"
      }
    } catch (e) {
      return "en"

    }
  }

}
