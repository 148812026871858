<div class="cources-module">

    <app-search [searchbyWord]="searchbyWord" [searchbyCategory]="searchbyCategory"></app-search>

    <div class="container d-flex m-md-3">
        <button (click)="createCource()" mat-mini-fab color="primary" style="background-color: #FFC825;"
            aria-label="Example icon button with a delete icon">
            <mat-icon> add</mat-icon>
        </button>

        <h3 (click)="createCource()" role="button"  class="mx-4 w-auto ">  {{ 'ADD_COURSE' | translate }}</h3>
    </div>
    @if(currentPage){
    @for(cource of currentPage.content ; track cource.id){
    <div class="m-4">
        <table class="table container">
            <thead>
                <tr class="row">
                    <th class="col-md-1"> {{ 'COLUMN_NUMBER' | translate }} </th>
                    <th class="col-md-5"> {{ 'COLUMN_NAME' | translate }} </th>
                    <th class="col-md-3"> {{ 'COLUMN_PRICE' | translate }} </th>
                    <th class="col-md-3"> {{ 'COLUMN_ACTIONS' | translate }} </th>
                </tr>
            </thead>
            <tbody>
                <tr class="row">
                    <td class="col-md-1"> {{cource.countUsers}} </td>
                    <td class="col-md-5"> {{cource.name}} </td>
                    <td class="col-md-3"> {{cource.price}} $ </td>
                    <td class="col-md-3">
                        <button (click)="openDialog(cource.id)" mat-mini-fab color="primary"
                            style="background-color: #FFC825;" aria-label="Example icon button with a delete icon"
                            class="button-spacing">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <button (click)="updateCource(cource.id)" mat-mini-fab color="primary"
                            style="background-color: #FFC825;" aria-label="Example icon button with a edit icon"
                            class="button-spacing">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button (click)="credateLeasson(cource.id)" mat-mini-fab color="primary"
                            style="background-color: #FFC825;" aria-label="Example icon button with a edit icon"
                            class="button-spacing">
                            <mat-icon>list</mat-icon>
                        </button>
                        <button (click)="updateExam(cource.id)" mat-mini-fab color="primary"
                            style="background-color: #FFC825;" aria-label="Example icon button with a edit icon"
                            class="button-spacing">

                            <mat-icon>assignment</mat-icon>
                        </button>
                        <button (click)="updateFaq(cource.id)" mat-mini-fab color="primary"
                            style="background-color: #FFC825;" aria-label="Example icon button with a edit icon"
                            class="button-spacing">

                            <mat-icon>question_answer</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    } @empty {

    <div class="container p-md-4">
        <div class=" skeleton ">
            <div class="skeleton-left flex1">
                <div class="square"></div>
            </div>
            <div class="skeleton-right flex2">
                <div class="line h17 w40 m10"></div>
                <div class="line"></div>
                <div class="line h8 w50"></div>
                <div class="line  w75"></div>
            </div>
        </div>
    </div>

    }
    }

    <app-pagination [pageNumber]="pageNumber" [pages]="pages" [goToPage]="goToPage"></app-pagination>
</div>