<div>
    <label >1.Question text goes here. <span class="notes">1= Strongly Agree, 5 = Strongly Disagree.</span>  </label>

    <div class="answers-by-buttons">
        <button>1</button>
        <button>2</button>
        <button>3</button>
        <button>4</button>
        <button>5</button>
    </div>
</div>