<div class="row">
  <div class="col-4"></div>
  <a class="col-8" href="dashboard-admin/cources" style="padding-bottom: 20px; text-decoration: none;">
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 7.99992H17.5M8.33333 3.33325L2.5 7.99992L8.33333 3.33325ZM2.5 7.99992L8.33333 12.6666L2.5 7.99992Z"
        stroke="#282938" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    {{ 'HOMEPAGE' | translate }}
  </a>
</div>
<div class="container">
  <div>

    @if(pdfUrl){
    <pdf-viewer [src]="pdfUrl" [render-text]="true" style="display: block;" [show-all]="true" [zoom]="zoom">
    </pdf-viewer>
    }
    <label for="uploadphoto">
      <!-- {{ 'LABEL_UPLOAD_PHOTO' | translate }} -->
      {{ 'UPLOAD PDF' | translate }}
      <input class=" row btn btn-primary" style="background-color: #FFC825;" type="file" value="upload picture"
        (change)="uploadPDF($event)">
    </label>

  </div>
  <div>
    <h2>{{ 'HEADER_UPLOAD_VIDEO' | translate }}</h2>
    <input type="file" (change)="onFileSelected($event)">
    <button (click)="onUpload()">{{ 'BUTTON_UPLOAD' | translate }}</button>
    <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
  </div>


  <h2>{{ 'HEADER_STREAM_VIDEO' | translate }}</h2>

  @if(isloading) {
  <div class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
  }
  @if(videoUrl){
  <video width="600" controls>
    <source [src]="videoUrl" type="video/mp4">
    Your browser does not support the video tag.
  </video>
  }


  <div class="course-form">
    <mat-form-field>
      <mat-label>{{ 'LABEL_CHAPTER' | translate }}</mat-label>
      <input readonly [(ngModel)]="chapter" matInput>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'LABEL_TITLE' | translate }}</mat-label>
      <input type="text" [(ngModel)]="title" matInput>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'LABEL_INDEX_OF_LESSON' | translate }}</mat-label>
      <input type="number" [(ngModel)]="indexOfLeasson" matInput>
    </mat-form-field>
    <mat-form-field disabled>
      <mat-label>{{ 'LABEL_COURSES' | translate }} {{ cource }}</mat-label>
      <mat-select disabled="true" [(ngModel)]="cource">
        @for (item of cources; track item.id) {
        <mat-option value={{item.id}}>{{item.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>


  </div>

  <button class=" col-12 btn btn-primary" style="background-color: #FFC825;" (click)="createLeason()">{{
    'BUTTON_UPDATE_LESSON' | translate }}</button>

</div>